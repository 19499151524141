var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.drill_thru.automatic_insights.js");require("./metabase.lib.drill_thru.column_filter.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.drill_thru.distribution.js");require("./metabase.lib.drill_thru.fk_details.js");require("./metabase.lib.drill_thru.fk_filter.js");require("./metabase.lib.drill_thru.pk.js");require("./metabase.lib.drill_thru.zoom.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.util.malli.js");require("./metabase.lib.drill_thru.pivot.js");require("./metabase.lib.drill_thru.quick_filter.js");require("./metabase.lib.drill_thru.sort.js");require("./metabase.lib.drill_thru.summarize_column.js");require("./metabase.lib.drill_thru.summarize_column_by_time.js");require("./metabase.lib.drill_thru.underlying_records.js");require("./metabase.lib.drill_thru.zoom_in_bins.js");require("./metabase.lib.drill_thru.zoom_in_geographic.js");require("./metabase.lib.drill_thru.zoom_in_timeseries.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var m8,n8,o8,p8,q8,r8,Xta,s8,Yta,Zta,t8,u8,$ta,v8,w8,aua,x8,bua,cua,y8,z8,A8,B8,dua,C8,eua,fua,gua,hua,D8,iua,jua,E8,kua,lua,mua,nua,oua,F8,pua,qua,rua,sua,tua,uua,vua,G8,wua,xua,yua,zua,Aua,Bua,Cua,Dua,Eua,Fua,Gua,Hua,Iua,Jua,Kua,Lua,Mua,H8,I8,Nua,Oua,Pua,J8,Qua,Rua,Sua,Tua,K8,Uua,Vua,Wua,L8,Xua,Yua,Zua,$ua,ava;$CLJS.l8=function(a){return $CLJS.cc($CLJS.cb(function(b,c){return $CLJS.Zf.j(b,c,$CLJS.K.j(b,c,0)+1)},$CLJS.ac($CLJS.N),a))};
m8=function(a){var b=$CLJS.a_(a);return $CLJS.n(b)?$CLJS.ff($CLJS.NZ,$CLJS.g_(a,b)):$CLJS.xf};
n8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.K.g(c,$CLJS.Di),e=$CLJS.K.g(c,$CLJS.DK),f=$CLJS.K.g(c,$CLJS.KK),k=$CLJS.K.g(c,$CLJS.hj);return $CLJS.n(function(){var l=$CLJS.Y5(a,b);return l?(l=$CLJS.Be(f),$CLJS.n(l)?(l=$CLJS.n(d)?null!=k:d,l=$CLJS.n(l)?l:null==d&&null==k,$CLJS.n(l)?$CLJS.Ra($CLJS.n(d)?$CLJS.OZ(d):d):l):l):l}())?new $CLJS.h(null,6,[$CLJS.Vs,$CLJS.QK,$CLJS.Vi,$CLJS.Y6,$CLJS.S6,"number"!==typeof k||0>k?2:k,$CLJS.PY,function(){var l=null==a?null:$CLJS.a_(a);l=null==l?null:$CLJS.f_(a,l);
$CLJS.n(l)||(l=null==a?null:$CLJS.N1(a),l=null==l?null:$CLJS.h_(a,l));return $CLJS.n(l)?$CLJS.n_.j(a,b,l):null}(),$CLJS.KK,f,$CLJS.DK,e],null):null};
o8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.K.g(c,$CLJS.Di),e=$CLJS.K.g(c,$CLJS.DK),f=$CLJS.K.g(c,$CLJS.KK),k=$CLJS.K.g(c,$CLJS.hj);return $CLJS.n(function(){var l=$CLJS.Y5(a,b);return l?(l=$CLJS.Ra(d)||null!=k)?(l=$CLJS.J7(a,$CLJS.Bta),$CLJS.n(l)?$CLJS.Be(f):l):l:l}())?new $CLJS.h(null,4,[$CLJS.Vs,$CLJS.QK,$CLJS.Vi,$CLJS.C6,$CLJS.DK,e,$CLJS.KK,f],null):null};
p8=function(a,b,c,d){var e=$CLJS.u5(a,$CLJS.GV(a,b)),f=$CLJS.kk.g($CLJS.NJ.h(c),$CLJS.mK)?new $CLJS.h(null,2,[$CLJS.GE,a,$CLJS.HK,b],null):$CLJS.n(e)?new $CLJS.h(null,2,[$CLJS.GE,a,$CLJS.HK,e],null):new $CLJS.h(null,2,[$CLJS.GE,$CLJS.V7(a),$CLJS.HK,-1],null),k=$CLJS.e3.g($CLJS.GE.h(f),$CLJS.HK.h(f));a=function(){var l=$CLJS.X1.v($CLJS.GE.h(f),$CLJS.HK.h(f),d,k);if($CLJS.n(l))return l;l=$CLJS.F0.h(c);return $CLJS.n(l)?$CLJS.sG(function(m){return $CLJS.E.g($CLJS.F0.h(m),$CLJS.F0.h(c))},k):l}();return $CLJS.R.j(f,
$CLJS.Di,a)};q8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.K.g(d,$CLJS.Di);var e=$CLJS.K.g(d,$CLJS.DK);d=$CLJS.K.g(d,$CLJS.hj);var f=$CLJS.Y5(a,b);d=f?$CLJS.n(c)?null==d&&!$CLJS.OZ(c):c:f;return $CLJS.n(d)?(d=$CLJS.FZ(c)?null:$CLJS.R.j($CLJS.z($CLJS.u2(c)),$CLJS.Vs,$CLJS.QI),$CLJS.Dk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.Vs,$CLJS.QK,$CLJS.Vi,$CLJS.w6,$CLJS.u6,d],null),p8(a,b,c,e)]))):null};
r8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.K.g(d,$CLJS.Di);d=$CLJS.K.g(d,$CLJS.hj);var e=$CLJS.Y5(a,b);a=e?$CLJS.n(c)?null==d&&$CLJS.kk.g($CLJS.NJ.h(c),$CLJS.mK)&&!$CLJS.NZ(c)&&!$CLJS.OZ(c)&&!$CLJS.YZ(c)&&!$CLJS.LZ(c)&&$CLJS.Ra($CLJS.y($CLJS.W5.j(a,b,c))):c:e;return $CLJS.n(a)?new $CLJS.h(null,3,[$CLJS.Vs,$CLJS.QK,$CLJS.Vi,$CLJS.W6,$CLJS.Di,c],null):null};
Xta=function(a,b,c){c=$CLJS.O(c);b=$CLJS.K.g(c,$CLJS.Di);c=$CLJS.K.g(c,$CLJS.hj);return $CLJS.MZ(b)&&null!=c&&$CLJS.kk.g(c,$CLJS.PK)?new $CLJS.h(null,5,[$CLJS.Vs,$CLJS.QK,$CLJS.Vi,$CLJS.V6,$CLJS.Di,b,$CLJS.g6,c,$CLJS.b6,1<$CLJS.D(m8(a))],null):null};
s8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.K.g(d,$CLJS.Di);var e=$CLJS.K.g(d,$CLJS.DK);d=$CLJS.K.g(d,$CLJS.hj);if($CLJS.n($CLJS.n(c)?null!=d&&$CLJS.kk.g(d,$CLJS.PK)&&$CLJS.Y5(a,b)&&!$CLJS.NZ(c)&&$CLJS.MZ(c):c)){var f=null==a?null:$CLJS.a_(a);f=null==f?null:$CLJS.f_(a,f);$CLJS.n(f)||(f=null==a?null:$CLJS.N1(a),f=null==f?null:$CLJS.h_(a,f));return new $CLJS.h(null,5,[$CLJS.Vs,$CLJS.QK,$CLJS.Vi,$CLJS.A6,$CLJS.OE,$CLJS.rV(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kq,$CLJS.N,e,d],null)),$CLJS.VE,$CLJS.n_.v(a,
b,c,$CLJS.o_),$CLJS.PY,$CLJS.n_.j(a,0,f)],null)}return null};
Yta=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.K.g(d,$CLJS.Di),f=$CLJS.K.g(d,$CLJS.hj),k=$CLJS.K.g(d,$CLJS.Yh);if(null!=f&&$CLJS.Y5(a,b)&&1<$CLJS.D(m8(a))&&!$CLJS.MZ(e)){if($CLJS.NZ(e))return null!=f&&$CLJS.kk.g(f,$CLJS.PK)?new $CLJS.h(null,3,[$CLJS.Vs,$CLJS.QK,$CLJS.Vi,$CLJS.K6,$CLJS.KK,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Di,e,$CLJS.hj,f],null)],null)],null):null;var l=m8(a);a=function(){return function u(t){return new $CLJS.ie(null,function(){for(var v=t;;){var x=$CLJS.y(v);if(x){var A=
x;if($CLJS.wd(A)){var C=$CLJS.ic(A),G=$CLJS.D(C),J=$CLJS.le(G);return function(){for(var W=0;;)if(W<G){var da=$CLJS.id(C,W),ra=$CLJS.hj.h($CLJS.sG(function(Ma,sb,Qa){return function(Xa){return $CLJS.E.g($CLJS.T.h($CLJS.Di.h(Xa)),$CLJS.T.h(Qa))}}(W,v,da,C,G,J,A,x,l,c,d,d,e,f,k),k));$CLJS.n(ra)&&$CLJS.pe(J,new $CLJS.h(null,2,[$CLJS.Di,da,$CLJS.hj,ra],null));W+=1}else return!0}()?$CLJS.oe($CLJS.qe(J),u($CLJS.jc(A))):$CLJS.oe($CLJS.qe(J),null)}var S=$CLJS.z(A),X=$CLJS.hj.h($CLJS.sG(function(W,da){return function(ra){return $CLJS.E.g($CLJS.T.h($CLJS.Di.h(ra)),
$CLJS.T.h(da))}}(v,S,A,x,l,c,d,d,e,f,k),k));if($CLJS.n(X))return $CLJS.ae(new $CLJS.h(null,2,[$CLJS.Di,S,$CLJS.hj,X],null),u($CLJS.Hc(A)));v=$CLJS.Hc(A)}else return null}},null,null)}(l)}();return $CLJS.y(a)?new $CLJS.h(null,3,[$CLJS.Vs,$CLJS.QK,$CLJS.Vi,$CLJS.K6,$CLJS.KK,$CLJS.yf($CLJS.h1(function(m){return $CLJS.Bz(m,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Di,$CLJS.Ki],null))},a))],null):null}return null};
Zta=function(a,b,c){var d=$CLJS.O(c);b=$CLJS.K.g(d,$CLJS.Di);c=$CLJS.K.g(d,$CLJS.hj);d=$CLJS.K.g(d,$CLJS.Yh);if(null==c||1<$CLJS.D(m8(a)))return null;if($CLJS.NZ(b))return $CLJS.E.g(c,$CLJS.PK)?null:new $CLJS.h(null,5,[$CLJS.Vs,$CLJS.QK,$CLJS.Vi,$CLJS.m7,$CLJS.Di,b,$CLJS.g6,c,$CLJS.b6,!1],null);a=m8(a);var e=$CLJS.I(a,0,null);a=$CLJS.hj.h($CLJS.sG(function(f){return $CLJS.E.g($CLJS.T.h($CLJS.Di.h(f)),$CLJS.T.h(e))},d));return $CLJS.n(a)?null==a?null:new $CLJS.h(null,5,[$CLJS.Vs,$CLJS.QK,$CLJS.Vi,
$CLJS.m7,$CLJS.Di,e,$CLJS.g6,a,$CLJS.b6,!1],null):null};t8=function(a,b,c){return $CLJS.Me(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)},new $CLJS.P(null,3,5,$CLJS.Q,[Xta,Yta,Zta],null))};u8=function(a,b,c,d){var e=$CLJS.O(c);c=$CLJS.K.g(e,$CLJS.Di);e=$CLJS.K.g(e,$CLJS.hj);var f=$CLJS.Y5(a,b);c=f?$CLJS.n(c)?null!=e&&$CLJS.E.g($CLJS.NJ.h(c),$CLJS.mK):c:f;return $CLJS.n(c)?$CLJS.ff(d,$CLJS.V5.g(a,b)):null};
$ta=function(a,b){var c=$CLJS.l8($CLJS.cf.g(function(d){d=$CLJS.l_.j(a,b,d);return $CLJS.FZ(d)?$CLJS.EO:$CLJS.RZ(d)?$CLJS.W7:$CLJS.KZ(d)?$CLJS.CF:null},$CLJS.T5.g(a,b)));return $CLJS.E.g(new $CLJS.h(null,1,[$CLJS.EO,1],null),c)?new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.CF,null,$CLJS.q6,null],null),null):$CLJS.E.g(new $CLJS.h(null,2,[$CLJS.EO,1,$CLJS.CF,1],null),c)?new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.CF,null,$CLJS.q6,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.W7,1],null),c)?new $CLJS.Rg(null,
new $CLJS.h(null,2,[$CLJS.CF,null,$CLJS.Fx,null],null),null):$CLJS.E.g($CLJS.N,c)?new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.CF,null,$CLJS.Fx,null,$CLJS.q6,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.CF,1],null),c)?new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.CF,null,$CLJS.Fx,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.CF,2],null),c)?new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.CF,null,$CLJS.Fx,null],null),null):$CLJS.Sg};
v8=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.K.g(d,$CLJS.Di);c=$CLJS.K.g(d,$CLJS.KK);var f=$CLJS.K.g(d,$CLJS.hj);if($CLJS.n(function(){var m=$CLJS.Y5(a,b);return m?$CLJS.n(e)?null!=f&&$CLJS.E.g($CLJS.NJ.h(e),$CLJS.mK)&&0<$CLJS.D($CLJS.w4.g(a,b)):e:m}())){var k=$ta(a,b),l=$CLJS.Wf.g($CLJS.N,function(){return function u(t){return new $CLJS.ie(null,function(){for(var v=t;;)if(v=$CLJS.y(v)){if($CLJS.wd(v)){var x=$CLJS.ic(v),A=$CLJS.D(x),C=$CLJS.le(A);a:for(var G=0;;)if(G<A){var J=$CLJS.id(x,G),S=$CLJS.K.g($CLJS.X7,
J);S=u8(a,b,d,S);$CLJS.n($CLJS.Be(S))&&C.add(new $CLJS.P(null,2,5,$CLJS.Q,[J,S],null));G+=1}else{x=!0;break a}return x?$CLJS.oe($CLJS.qe(C),u($CLJS.jc(v))):$CLJS.oe($CLJS.qe(C),null)}C=$CLJS.z(v);x=$CLJS.K.g($CLJS.X7,C);x=u8(a,b,d,x);if($CLJS.n($CLJS.Be(x)))return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[C,x],null),u($CLJS.Hc(v)));v=$CLJS.Hc(v)}else return null},null,null)}(k)}());return $CLJS.pd(l)?null:new $CLJS.h(null,4,[$CLJS.Vs,$CLJS.QK,$CLJS.Vi,$CLJS.e7,$CLJS.KK,c,$CLJS.m6,l],null)}return null};
w8=function(a,b){return $CLJS.rV($CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.N],null),b))};
aua=function(a,b){var c=$CLJS.sV(a);if($CLJS.OZ(a))return $CLJS.xf;if($CLJS.E.g(b,$CLJS.PK)){var d=function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.wd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.id(m,v),A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);A=new $CLJS.h(null,2,[$CLJS.T,x,$CLJS.OE,w8(A,$CLJS.H([c]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}m=$CLJS.z(l);
u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);return $CLJS.ae(new $CLJS.h(null,2,[$CLJS.T,m,$CLJS.OE,w8(u,$CLJS.H([c]))],null),k($CLJS.Hc(l)))}return null}},null,null)};return d($CLJS.HZ(a)||$CLJS.IZ(a)?new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NE,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UE,"≠"],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LE,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XE,"≠"],null)],null))}if($CLJS.GZ(a)||$CLJS.FZ(a))return d=
function k(f){return new $CLJS.ie(null,function(){for(var l=f;;)if(l=$CLJS.y(l)){if($CLJS.wd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);return function(){for(var A=0;;)if(A<t){var C=$CLJS.id(m,A),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var J=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.Dq,null,$CLJS.Hq,null],null),null);J=J.h?J.h(G):J.call(null,G);J=(J=$CLJS.Ra(J))?J:$CLJS.NI(c,b);$CLJS.n(J)&&(G=new $CLJS.h(null,2,[$CLJS.T,C,$CLJS.OE,w8(G,$CLJS.H([c,b]))],null),u.add(G));A+=1}else return!0}()?
$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}var v=$CLJS.z(l),x=$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);if($CLJS.n(function(){var A=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.Dq,null,$CLJS.Hq,null],null),null);A=A.h?A.h(x):A.call(null,x);return(A=$CLJS.Ra(A))?A:$CLJS.NI(c,b)}()))return $CLJS.ae(new $CLJS.h(null,2,[$CLJS.T,v,$CLJS.OE,w8(x,$CLJS.H([c,b]))],null),k($CLJS.Hc(l)));l=$CLJS.Hc(l)}else return null},null,null)},d(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.Hq,"\x3c"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dq,"\x3e"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AF,"≠"],null)],null));if($CLJS.HZ(a)&&($CLJS.YZ(a)||$CLJS.LZ(a)))return d=function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.wd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.id(m,v),A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);A=new $CLJS.h(null,2,[$CLJS.T,x,$CLJS.OE,
w8(A,$CLJS.H([c,b]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}m=$CLJS.z(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);return $CLJS.ae(new $CLJS.h(null,2,[$CLJS.T,m,$CLJS.OE,w8(u,$CLJS.H([c,b]))],null),k($CLJS.Hc(l)))}return null}},null,null)},d(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jF,"contains"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cF,"does-not-contain"],null)],null));d=function k(f){return new $CLJS.ie(null,
function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.wd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.id(m,v),A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);A=new $CLJS.h(null,2,[$CLJS.T,x,$CLJS.OE,w8(A,$CLJS.H([c,b]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}m=$CLJS.z(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);return $CLJS.ae(new $CLJS.h(null,2,[$CLJS.T,m,$CLJS.OE,w8(u,$CLJS.H([c,b]))],null),k($CLJS.Hc(l)))}return null}},
null,null)};return d(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AF,"≠"],null)],null))};
x8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.K.g(d,$CLJS.Di);var e=$CLJS.K.g(d,$CLJS.DK);d=$CLJS.K.g(d,$CLJS.hj);var f=(f=$CLJS.Y5(a,b))?$CLJS.n(c)?null!=d&&!$CLJS.OZ(c)&&!$CLJS.NZ(c)&&!$CLJS.MZ(c):c:f;return $CLJS.n(f)?(a=p8(a,b,c,e),$CLJS.Dk.l($CLJS.H([new $CLJS.h(null,4,[$CLJS.Vs,$CLJS.QK,$CLJS.Vi,$CLJS.f6,$CLJS.FK,aua($CLJS.Di.h(a),d),$CLJS.hj,d],null),a]))):null};
bua=function(a,b,c){return $CLJS.sG(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);d=$CLJS.I(d,2,null);return $CLJS.X1.v(a,b,d,new $CLJS.P(null,1,5,$CLJS.Q,[c],null))},$CLJS.a8.g(a,b))};cua=function(a,b,c){a=bua(a,b,c);return $CLJS.n(a)?(b=$CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),b):null};
y8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.K.g(c,$CLJS.Di),e=$CLJS.K.g(c,$CLJS.DK),f=$CLJS.K.g(c,$CLJS.hj);if($CLJS.n(function(){var l=$CLJS.Y5(a,b);return l?$CLJS.n(d)?null==f&&!$CLJS.OZ(d):d:l}())&&$CLJS.n($CLJS.X1.v(a,b,e,$CLJS.b8.g(a,b)))){var k=cua(a,b,d);return new $CLJS.h(null,4,[$CLJS.Vs,$CLJS.QK,$CLJS.Vi,$CLJS.G6,$CLJS.Di,d,$CLJS.J6,function(){switch(k instanceof $CLJS.M?k.S:null){case "asc":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.aF],null);case "desc":return new $CLJS.P(null,1,5,$CLJS.Q,
[$CLJS.hF],null);default:return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hF,$CLJS.aF],null)}}()],null)}return null};
z8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.K.g(d,$CLJS.Di);d=$CLJS.K.g(d,$CLJS.hj);var e=$CLJS.Y5(a,b);a=e?$CLJS.n(c)?null==d&&!$CLJS.OZ(c)&&$CLJS.kk.g($CLJS.NJ.h(c),$CLJS.mK)&&$CLJS.Ra($CLJS.y($CLJS.W5.j(a,b,c))):c:e;return $CLJS.n(a)?(a=$CLJS.bf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.PE],null),$CLJS.JZ(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wF,$CLJS.eF],null):null),new $CLJS.h(null,4,[$CLJS.Vs,$CLJS.QK,$CLJS.Vi,$CLJS.H6,$CLJS.Di,c,$CLJS.M6,a],null)):null};
A8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.K.g(d,$CLJS.Di);d=$CLJS.K.g(d,$CLJS.hj);var e=$CLJS.Y5(a,b);d=e?$CLJS.n(c)?null==d&&!$CLJS.OZ(c)&&$CLJS.JZ(c)&&$CLJS.kk.g($CLJS.NJ.h(c),$CLJS.mK):c:e;if($CLJS.n(d)&&(d=$CLJS.sG($CLJS.FZ,$CLJS.V5.g(a,b)),$CLJS.n(d)&&(e=$CLJS.sG($CLJS.Oh,$CLJS.z0.j(a,b,d)),$CLJS.n(e)))){var f=$CLJS.j3(d,e);return $CLJS.n($CLJS.LU($CLJS.cf.g($CLJS.sV,$CLJS.ae(f,$CLJS.T5.g(a,b)))))?new $CLJS.h(null,5,[$CLJS.Vs,$CLJS.QK,$CLJS.Vi,$CLJS.b7,$CLJS.Di,c,$CLJS.ZE,d,$CLJS.ei,$CLJS.w0.h(e)],
null):null}return null};
B8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.K.g(d,$CLJS.Di);d=$CLJS.K.g(d,$CLJS.hj);if($CLJS.n($CLJS.n(c)?d:c))if(b=$CLJS.z($CLJS.W5.j(a,b,c)),$CLJS.n(b))if(b=$CLJS.j5(b),$CLJS.n(b))if(a=$CLJS.L7(a,c,d),$CLJS.n(a)){var e=$CLJS.O(a);a=$CLJS.K.g(e,$CLJS.JK);var f=$CLJS.K.g(e,$CLJS.MK);e=$CLJS.K.g(e,$CLJS.BD);var k=$CLJS.CD.h(b);k=k instanceof $CLJS.M?k.S:null;switch(k){case "num-bins":case "default":return new $CLJS.h(null,6,[$CLJS.Vs,$CLJS.QK,$CLJS.Vi,$CLJS.L6,$CLJS.Di,c,$CLJS.JK,d,$CLJS.MK,d+e,$CLJS.D6,
new $CLJS.h(null,1,[$CLJS.CD,$CLJS.Oh],null)],null);case "bin-width":return new $CLJS.h(null,6,[$CLJS.Vs,$CLJS.QK,$CLJS.Vi,$CLJS.L6,$CLJS.Di,c,$CLJS.JK,a,$CLJS.MK,f,$CLJS.D6,$CLJS.ok.j(b,$CLJS.BD,function(l){return l/10})],null);default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}else return null;else return null;else return null;else return null};
dua=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.K.g(c,$CLJS.Yh),e=$CLJS.HV(a,b);b=$CLJS.Me(function(f){var k=$CLJS.sG($CLJS.WZ,f);return $CLJS.n(k)?(f=$CLJS.sG($CLJS.XZ,f),$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null):null):null},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Y_.j(a,b,e),$CLJS.$_.j(a,b,e)],null));a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.n($CLJS.n(a)?b:a)?(e=function(f){return $CLJS.Me(function(k){var l=$CLJS.Di.h(k);l=$CLJS.n($CLJS.Ki.h(f))?$CLJS.E.g($CLJS.Ki.h(f),$CLJS.Ki.h(l)):
$CLJS.E.g($CLJS.NY.h(f),$CLJS.NY.h(l));return $CLJS.n(l)?$CLJS.hj.h(k):null},d)},$CLJS.R.l(c,$CLJS.g8,a,$CLJS.H([$CLJS.h8,b,$CLJS.i8,e(a),$CLJS.f8,e(b)]))):null};
C8=function(a,b){var c=$CLJS.O(a);a=$CLJS.K.g(c,$CLJS.Di);var d=$CLJS.K.g(c,$CLJS.hj),e=$CLJS.K.g(c,$CLJS.g8);c=$CLJS.K.g(c,$CLJS.h8);return $CLJS.n(d)?new $CLJS.h(null,7,[$CLJS.Vs,$CLJS.QK,$CLJS.Vi,$CLJS.i7,$CLJS.k6,$CLJS.y6,$CLJS.Di,a,$CLJS.hj,d,$CLJS.B6,new $CLJS.h(null,2,[$CLJS.Di,e,$CLJS.BD,b],null),$CLJS.z6,new $CLJS.h(null,2,[$CLJS.Di,c,$CLJS.BD,b],null)],null):null};eua=function(a){a=$CLJS.O(a);var b=$CLJS.K.g(a,$CLJS.Di);return $CLJS.n(null==b?null:$CLJS.UZ(b))?C8(a,10):null};
fua=function(a){a=$CLJS.O(a);var b=$CLJS.K.g(a,$CLJS.Di);return $CLJS.n(null==b?null:$CLJS.TZ(b))?C8(a,1):null};gua=function(a){a=$CLJS.O(a);var b=$CLJS.K.g(a,$CLJS.Di);return $CLJS.n(null==b?null:$CLJS.SZ(b))?C8(a,.1):null};
hua=function(a,b){var c=$CLJS.O(b);b=$CLJS.K.g(c,$CLJS.g8);var d=$CLJS.K.g(c,$CLJS.h8),e=$CLJS.K.g(c,$CLJS.i8);c=$CLJS.K.g(c,$CLJS.f8);if($CLJS.n($CLJS.n(e)?c:e)){var f=$CLJS.L7(a,b,e);if($CLJS.n(f)&&(f=$CLJS.O(f),f=$CLJS.K.g(f,$CLJS.BD),a=$CLJS.L7(a,d,c),$CLJS.n(a))){a=$CLJS.O(a);a=$CLJS.K.g(a,$CLJS.BD);var k=20<=f&&20<=a?new $CLJS.P(null,2,5,$CLJS.Q,[10,10],null):new $CLJS.P(null,2,5,$CLJS.Q,[f/10,a/10],null),l=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return new $CLJS.h(null,5,[$CLJS.Vs,$CLJS.QK,$CLJS.Vi,
$CLJS.i7,$CLJS.k6,$CLJS.d6,$CLJS.B6,new $CLJS.h(null,4,[$CLJS.Di,b,$CLJS.BD,l,$CLJS.ui,e,$CLJS.Gj,e+f],null),$CLJS.z6,new $CLJS.h(null,4,[$CLJS.Di,d,$CLJS.BD,k,$CLJS.ui,c,$CLJS.Gj,c+a],null)],null)}}return null};D8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.K.g(c,$CLJS.hj);if($CLJS.n(d)){var e=dua(a,b,c);return $CLJS.n(e)?$CLJS.Me(function(f){return f.h?f.h(e):f.call(null,e)},new $CLJS.P(null,4,5,$CLJS.Q,[eua,fua,gua,$CLJS.Qe(hua,a)],null)):null}return null};
iua=function(a,b,c){return $CLJS.z(function(){return function f(e){return new $CLJS.ie(null,function(){for(var k=e;;){var l=$CLJS.y(k);if(l){var m=l,t=$CLJS.z(m);if($CLJS.n(function(){var u=$CLJS.K1(t,$CLJS.kF);return u?$CLJS.q2(t):u}())&&(l=$CLJS.y(function(u,v,x,A){return function J(G){return new $CLJS.ie(null,function(S,X){return function(){for(var W=G;;)if(W=$CLJS.y(W)){if($CLJS.wd(W)){var da=$CLJS.ic(W),ra=$CLJS.D(da),Ma=$CLJS.le(ra);return function(){for(var Ja=0;;)if(Ja<ra){var Va=$CLJS.id(da,
Ja),Ta=$CLJS.O(Va);Va=Ta;Ta=$CLJS.K.g(Ta,$CLJS.Di);var mb=$CLJS.X1.g(X,new $CLJS.P(null,1,5,$CLJS.Q,[Ta],null));Ta=$CLJS.n(mb)?$CLJS.E.g($CLJS.q2(X),$CLJS.q2(Ta)):mb;$CLJS.n(Ta)&&(Va=$CLJS.R.j(Va,$CLJS.DK,X),Ma.add(Va));Ja+=1}else return!0}()?$CLJS.oe($CLJS.qe(Ma),J($CLJS.jc(W))):$CLJS.oe($CLJS.qe(Ma),null)}var sb=$CLJS.z(W),Qa=sb=$CLJS.O(sb),Xa=$CLJS.K.g(sb,$CLJS.Di);if($CLJS.n(function(){var Ja=$CLJS.X1.g(X,new $CLJS.P(null,1,5,$CLJS.Q,[Xa],null));return $CLJS.n(Ja)?$CLJS.E.g($CLJS.q2(X),$CLJS.q2(Xa)):
Ja}()))return $CLJS.ae($CLJS.R.j(Qa,$CLJS.DK,X),J($CLJS.Hc(W)));W=$CLJS.Hc(W)}else return null}}(u,v,x,A),null,null)}}(k,t,m,l)(c))))return $CLJS.bf.g(l,f($CLJS.Hc(k)));k=$CLJS.Hc(k)}else return null}},null,null)}($CLJS.T5.g(a,b))}())};
jua=function(a){a=a instanceof $CLJS.M?a.S:null;switch(a){case "quarter":return $CLJS.JD("See this year by quarter");case "month":return $CLJS.JD("See this quarter by month");case "week":return $CLJS.JD("See this month by week");case "day":return $CLJS.JD("See this week by day");case "hour":return $CLJS.JD("See this day by hour");case "minute":return $CLJS.JD("See this hour by minute");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}};
E8=function(a,b,c){c=$CLJS.O(c);c=$CLJS.K.g(c,$CLJS.KK);var d=(d=$CLJS.Y5(a,b))?$CLJS.Be(c):d;return $CLJS.n(d)&&(a=iua(a,b,c),$CLJS.n(a)&&(a=$CLJS.O(a),b=$CLJS.K.g(a,$CLJS.hj),$CLJS.n(b)))?(b=$CLJS.Di.h(a),b=$CLJS.w0.h(b),b=$CLJS.n(b)?$CLJS.Dd($CLJS.Wg($CLJS.j8),b)?$CLJS.k8.h?$CLJS.k8.h(b):$CLJS.k8.call(null,b):null:null,$CLJS.n(b)?new $CLJS.h(null,5,[$CLJS.Vs,$CLJS.QK,$CLJS.lD,jua(b),$CLJS.Vi,$CLJS.g7,$CLJS.DE,a,$CLJS.a7,b],null):null):null};
kua=function(a){var b=$CLJS.O(a),c=$CLJS.K.g(b,$CLJS.KK);return $CLJS.Be(function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.id(l,u);v=$CLJS.Dk.l($CLJS.H([b,v]));t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}t=$CLJS.z(k);return $CLJS.ae($CLJS.Dk.l($CLJS.H([b,t])),f($CLJS.Hc(k)))}return null}},null,
null)}(c)}())};lua=new $CLJS.r(null,"metabase.lib.drill-thru.pivot","metabase.lib.drill-thru.pivot",109208756,null);mua=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-bins","metabase.lib.drill-thru.zoom-in-bins",477390405,null);nua=new $CLJS.r("metabase.lib.drill-thru.zoom-in-bins","zoom-in-binning-drill","metabase.lib.drill-thru.zoom-in-bins/zoom-in-binning-drill",-862934837,null);
oua=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column-by-time","metabase.lib.drill-thru.summarize-column-by-time",-369504986,null);F8=new $CLJS.r(null,"stage-number","stage-number",-112198111,null);pua=new $CLJS.r(null,"underlying-records-drill","underlying-records-drill",713740052,null);qua=new $CLJS.r("metabase.lib.drill-thru.zoom-in-timeseries","zoom-in-timeseries-drill","metabase.lib.drill-thru.zoom-in-timeseries/zoom-in-timeseries-drill",313862423,null);
rua=new $CLJS.r(null,"zoom-in-timeseries-drill","zoom-in-timeseries-drill",-1304664195,null);sua=new $CLJS.r(null,"distribution-drill","distribution-drill",-898205041,null);tua=new $CLJS.r(null,"sort-drill","sort-drill",600387798,null);uua=new $CLJS.M(null,"drill","drill",-1704624793);vua=new $CLJS.r("metabase.lib.drill-thru.quick-filter","quick-filter-drill","metabase.lib.drill-thru.quick-filter/quick-filter-drill",457872114,null);G8=new $CLJS.r(null,"_context","_context",-1238791322,null);
wua=new $CLJS.r(null,"metabase.lib.drill-thru.distribution","metabase.lib.drill-thru.distribution",-514507852,null);xua=new $CLJS.r(null,"zoom-in-binning-drill","zoom-in-binning-drill",1550598377,null);yua=new $CLJS.r(null,"metabase.lib.drill-thru.object-details","metabase.lib.drill-thru.object-details",1788168194,null);zua=new $CLJS.r(null,"automatic-insights-drill","automatic-insights-drill",-538289251,null);
Aua=new $CLJS.r("metabase.lib.drill-thru.zoom-in-geographic","zoom-in-geographic-drill","metabase.lib.drill-thru.zoom-in-geographic/zoom-in-geographic-drill",754461586,null);Bua=new $CLJS.r("metabase.lib.drill-thru.distribution","distribution-drill","metabase.lib.drill-thru.distribution/distribution-drill",-2103693273,null);Cua=new $CLJS.r(null,"fk-filter-drill","fk-filter-drill",-1258190784,null);
Dua=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column","metabase.lib.drill-thru.summarize-column",490384430,null);Eua=new $CLJS.r(null,"pivot-drill","pivot-drill",-1794856768,null);Fua=new $CLJS.r(null,"metabase.lib.drill-thru.quick-filter","metabase.lib.drill-thru.quick-filter",671398986,null);Gua=new $CLJS.r(null,"metabase.lib.drill-thru.underlying-records","metabase.lib.drill-thru.underlying-records",-827008688,null);
Hua=new $CLJS.r("metabase.lib.drill-thru.summarize-column-by-time","summarize-column-by-time-drill","metabase.lib.drill-thru.summarize-column-by-time/summarize-column-by-time-drill",391970943,null);Iua=new $CLJS.r(null,"summarize-column-drill","summarize-column-drill",-1961542613,null);Jua=new $CLJS.r(null,"column-filter-drill","column-filter-drill",-1088669153,null);
Kua=new $CLJS.r("metabase.lib.drill-thru.underlying-records","underlying-records-drill","metabase.lib.drill-thru.underlying-records/underlying-records-drill",1176519262,null);Lua=new $CLJS.r(null,"metabase.lib.drill-thru.sort","metabase.lib.drill-thru.sort",961185366,null);Mua=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-geographic","metabase.lib.drill-thru.zoom-in-geographic",-1562146002,null);H8=new $CLJS.r(null,"dimensions","dimensions",1385713430,null);
I8=new $CLJS.M(null,"return-drills-for-dimensions?","return-drills-for-dimensions?",-1759804348);Nua=new $CLJS.r("metabase.lib.drill-thru.column-filter","column-filter-drill","metabase.lib.drill-thru.column-filter/column-filter-drill",-1130871894,null);Oua=new $CLJS.r(null,"object-detail-drill","object-detail-drill",-613919053,null);Pua=new $CLJS.r(null,"metabase.lib.drill-thru.column-filter","metabase.lib.drill-thru.column-filter",23504270,null);
J8=new $CLJS.r(null,"context","context",810340414,null);Qua=new $CLJS.r(null,"zoom-in-geographic-drill","zoom-in-geographic-drill",312914971,null);Rua=new $CLJS.r("metabase.lib.drill-thru.automatic-insights","automatic-insights-drill","metabase.lib.drill-thru.automatic-insights/automatic-insights-drill",1471807986,null);Sua=new $CLJS.r(null,"summarize-column-by-time-drill","summarize-column-by-time-drill",1117784387,null);
Tua=new $CLJS.r("metabase.lib.drill-thru.object-details","object-detail-drill","metabase.lib.drill-thru.object-details/object-detail-drill",-1376576725,null);K8=new $CLJS.r(null,"column-ref","column-ref",-636247393,null);Uua=new $CLJS.r("metabase.lib.drill-thru.pivot","pivot-drill","metabase.lib.drill-thru.pivot/pivot-drill",1004065694,null);Vua=new $CLJS.r(null,"metabase.lib.drill-thru.fk-filter","metabase.lib.drill-thru.fk-filter",-1939155669,null);
Wua=new $CLJS.r("metabase.lib.drill-thru.summarize-column","summarize-column-drill","metabase.lib.drill-thru.summarize-column/summarize-column-drill",-894275347,null);L8=new $CLJS.r(null,"query","query",352022017,null);Xua=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-timeseries","metabase.lib.drill-thru.zoom-in-timeseries",105846904,null);Yua=new $CLJS.r(null,"metabase.lib.drill-thru.automatic-insights","metabase.lib.drill-thru.automatic-insights",912989182,null);
Zua=new $CLJS.r("metabase.lib.drill-thru.fk-filter","fk-filter-drill","metabase.lib.drill-thru.fk-filter/fk-filter-drill",-716335012,null);$ua=new $CLJS.r("metabase.lib.drill-thru.sort","sort-drill","metabase.lib.drill-thru.sort/sort-drill",2019212116,null);ava=new $CLJS.r(null,"quick-filter-drill","quick-filter-drill",534264366,null);var bva;$CLJS.W_.m(null,$CLJS.QK,function(a,b,c){return $CLJS.a6.j(a,b,c)});
bva=new $CLJS.P(null,14,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return o8},Rua,$CLJS.zg([$CLJS.Sk,$CLJS.Hi,$CLJS.T,$CLJS.Lj,$CLJS.gi,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kj,$CLJS.NK,$CLJS.ji,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.F6],null)],null),Yua,zua,"metabase/lib/drill_thru/automatic_insights.cljc",34,1,11,11,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[L8,F8,new $CLJS.h(null,
1,[$CLJS.mo,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Xy,K8,H8,$CLJS.Fi],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru]\n          \n\n  Automatic insights appears:\n  - When clicking on a value with a breakout - eg. a point in a time series, a cell of a table, a bar or pie slice\n  - Or when clicking a pivot cell, with a value but no column.\n  - Or when clicking a chart legend, in which case there\'s no column or value set.\n  - There must be at least 1 breakout\n  - X-rays must be enabled (check the settings)\n\n  There are two forms: X-ray, and "Compare to the rest". This is a simple user choice and does not need extra data.',
$CLJS.n(o8)?o8.H:null])),I8,!1],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return q8},Nua,$CLJS.zg([$CLJS.Sk,$CLJS.Hi,$CLJS.T,$CLJS.Lj,$CLJS.gi,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kj,$CLJS.NK,$CLJS.ji,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.s6],null)],null),Pua,Jua,"metabase/lib/drill_thru/column_filter.cljc",29,1,82,82,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[L8,F8,new $CLJS.h(null,
1,[$CLJS.mo,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xy,K8,$CLJS.Fi],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.column-filter]\n          \n\n  Filtering at the column level, based on its type. Displays a submenu of eg. "Today", "This Week", etc. for date\n  columns.\n\n  Note that if the clicked column is an aggregation, filtering by it will require a new stage. Therefore this drill\n  returns a possibly-updated `:query` and `:stage-number` along with a `:column` referencing that later stage.',
$CLJS.n(q8)?q8.H:null])),I8,!0],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return r8},Bua,$CLJS.zg([$CLJS.Sk,$CLJS.Hi,$CLJS.T,$CLJS.Lj,$CLJS.gi,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kj,$CLJS.NK,$CLJS.ji,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.j6],null)],null),wua,sua,"metabase/lib/drill_thru/distribution.cljc",28,1,42,42,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[L8,F8,new $CLJS.h(null,
1,[$CLJS.mo,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xy,$CLJS.Fi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.distribution]\n          \n\n  Select a column and see a histogram of how many rows fall into an automatic set of bins/buckets.\n  - For dates, breaks out by month by default.\n  - For numeric values, by an auto-selected set of bins\n  - For strings, by each distinct value (which might be \x3d the number of rows)",
$CLJS.n(r8)?r8.H:null])),I8,!0],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return s8},Zua,$CLJS.zg([$CLJS.Sk,$CLJS.Hi,$CLJS.T,$CLJS.Lj,$CLJS.gi,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kj,$CLJS.NK,$CLJS.ji,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.Z6],null)],null),Vua,Cua,"metabase/lib/drill_thru/fk_filter.cljc",25,1,37,37,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[L8,F8,new $CLJS.h(null,
2,[$CLJS.mo,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xy,K8,$CLJS.Fi],null),$CLJS.Xr,G8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.fk-filter]\n          \n\n  When clicking on a foreign key value, filter this query by that column.\n\n  This has the same effect as the `\x3d` filter on a generic field (ie. not a key), but renders differently.\n\n  Contrast [[metabase.lib.drill-thru.object-details/object-detail-drill]], which shows the details of the foreign\n  object.",
$CLJS.n(s8)?s8.H:null])),I8,!1],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return t8},Tua,$CLJS.zg([$CLJS.Sk,$CLJS.Hi,$CLJS.T,$CLJS.Lj,$CLJS.gi,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kj,$CLJS.NK,$CLJS.ji,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,$CLJS.e6,$CLJS.$6,$CLJS.P6],null)],null)],null),yua,Oua,"metabase/lib/drill_thru/object_details.cljc",
29,1,10,10,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[L8,F8,J8],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int context :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe\n           [:or\n            :metabase.lib.schema.drill-thru/drill-thru.pk\n            :metabase.lib.schema.drill-thru/drill-thru.zoom\n            :metabase.lib.schema.drill-thru/drill-thru.fk-details]]\n          \n\n  When clicking a foreign key or primary key value, drill through to the details for that specific object.\n\n  Contrast [[metabase.lib.drill-thru.fk-filter/fk-filter-drill]], which filters this query to only those rows with a\n  specific value for a FK column.",
$CLJS.n(t8)?t8.H:null])),I8,!1],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return v8},Uua,$CLJS.zg([$CLJS.Sk,$CLJS.Hi,$CLJS.T,$CLJS.Lj,$CLJS.gi,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kj,$CLJS.NK,$CLJS.ji,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.n6],null)],null),lua,Eua,"metabase/lib/drill_thru/pivot.cljc",21,1,119,119,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[L8,F8,new $CLJS.h(null,
2,[$CLJS.mo,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xy,H8,$CLJS.Fi],null),$CLJS.Xr,J8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column dimensions value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.pivot]\n          \n\n  Return all possible pivoting options on the given column and value.\n\n  See `:pivots` key, which holds a map `{t [breakouts...]}` where `t` is `:category`, `:location`, or `:time`.\n  If a key is missing, there are no breakouts of that kind.",
$CLJS.n(v8)?v8.H:null])),I8,!1],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return x8},vua,$CLJS.zg([$CLJS.Sk,$CLJS.Hi,$CLJS.T,$CLJS.Lj,$CLJS.gi,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kj,$CLJS.NK,$CLJS.ji,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.Q6],null)],null),Fua,ava,"metabase/lib/drill_thru/quick_filter.cljc",28,1,96,96,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[L8,F8,new $CLJS.h(null,
2,[$CLJS.mo,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xy,K8,$CLJS.Fi],null),$CLJS.Xr,G8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.quick-filter]\n          \n\n  Filter the current query based on the value clicked.\n\n  The options vary depending on the type of the field:\n  - `:is-null` and `:not-null` for a `NULL` value;\n  - `:\x3d` and `:!\x3d` for everything else;\n  - plus `:\x3c` and `:\x3e` for numeric and date columns.\n\n  Note that this returns a single `::drill-thru` value with 1 or more `:operators`; these are rendered as a set of small\n  buttons in a single row of the drop-down.",
$CLJS.n(x8)?x8.H:null])),I8,!1],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return y8},$ua,$CLJS.zg([$CLJS.Sk,$CLJS.Hi,$CLJS.T,$CLJS.Lj,$CLJS.gi,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kj,$CLJS.NK,$CLJS.ji,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.j7],null)],null),Lua,tua,"metabase/lib/drill_thru/sort.cljc",20,1,51,51,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[L8,F8,new $CLJS.h(null,
2,[$CLJS.mo,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xy,K8,$CLJS.Fi],null),$CLJS.Xr,G8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.sort]\n          \n\n  Sorting on a clicked column.",$CLJS.n(y8)?y8.H:null])),I8,!0],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return z8},Wua,$CLJS.zg([$CLJS.Sk,$CLJS.Hi,
$CLJS.T,$CLJS.Lj,$CLJS.gi,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kj,$CLJS.NK,$CLJS.ji,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.v6],null)],null),Dua,Iua,"metabase/lib/drill_thru/summarize_column.cljc",32,1,31,31,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[L8,F8,new $CLJS.h(null,1,[$CLJS.mo,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xy,$CLJS.Fi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column]\n          \n\n  A set of possible aggregations that can summarize this column: distinct values, sum, average.\n  Separate from [[summarize-column-by-time-drill]] which breaks out a column over time.",
$CLJS.n(z8)?z8.H:null])),I8,!0],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return A8},Hua,$CLJS.zg([$CLJS.Sk,$CLJS.Hi,$CLJS.T,$CLJS.Lj,$CLJS.gi,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kj,$CLJS.NK,$CLJS.ji,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.t6],null)],null),oua,Sua,"metabase/lib/drill_thru/summarize_column_by_time.cljc",40,1,40,40,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,
[L8,F8,new $CLJS.h(null,1,[$CLJS.mo,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xy,$CLJS.Fi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column-by-time]\n          \n\n  A breakout summarizing a column over time.\n  Separate from single-value [[summarize-column-drill]] for sum, average, and distinct value count.",$CLJS.n(A8)?
A8.H:null])),I8,!0],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return n8},Kua,$CLJS.zg([$CLJS.Sk,$CLJS.Hi,$CLJS.T,$CLJS.Lj,$CLJS.gi,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kj,$CLJS.NK,$CLJS.ji,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.h7],null)],null),Gua,pua,"metabase/lib/drill_thru/underlying_records.cljc",34,1,51,51,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[L8,F8,new $CLJS.h(null,
2,[$CLJS.mo,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Xy,K8,H8,$CLJS.Fi],null),$CLJS.Xr,G8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.underlying-records]\n          \n\n  When clicking on a particular broken-out group, offer a look at the details of all the rows that went into this\n  bucket. Eg. distribution of People by State, then click New York and see the table of all People filtered by\n  `STATE \x3d 'New York'`.\n\n  There is another quite different case: clicking the legend of a chart with multiple bars or lines broken out by\n  category. Then `column` is nil!",
$CLJS.n(n8)?n8.H:null])),I8,!1],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return E8},qua,$CLJS.zg([$CLJS.Sk,$CLJS.Hi,$CLJS.T,$CLJS.Lj,$CLJS.gi,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kj,$CLJS.NK,$CLJS.ji,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.c7],null)],null),Xua,rua,"metabase/lib/drill_thru/zoom_in_timeseries.cljc",34,1,89,89,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[L8,F8,
new $CLJS.h(null,2,[$CLJS.mo,new $CLJS.P(null,1,5,$CLJS.Q,[H8],null),$CLJS.Xr,G8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [dimensions], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries]\n          \n\n  Zooms in on some window, showing it in finer detail.\n\n  For example: The month of a year, days or weeks of a quarter, smaller lat/long regions, etc.\n\n  This is different from the `:drill-thru/zoom` type, which is for showing the details of a single object.",
$CLJS.n(E8)?E8.H:null])),I8,!1],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return D8},Aua,$CLJS.zg([$CLJS.Sk,$CLJS.Hi,$CLJS.T,$CLJS.Lj,$CLJS.gi,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kj,$CLJS.NK,$CLJS.ji,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.d7],null)],null),Mua,Qua,"metabase/lib/drill_thru/zoom_in_geographic.cljc",34,1,189,189,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[L8,
F8,new $CLJS.h(null,2,[$CLJS.mo,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Fi],null),$CLJS.Xr,J8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.geographic]\n          \n\n  Return a `:drill-thru/zoom-in.geographic` drill if appropriate. See docstring\n  for [[metabase.lib.drill-thru.zoom-in-geographic]] for more information on what circumstances this is returned in\n  and what it means to apply this drill.",
$CLJS.n(D8)?D8.H:null])),I8,!0],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return B8},nua,$CLJS.zg([$CLJS.Sk,$CLJS.Hi,$CLJS.T,$CLJS.Lj,$CLJS.gi,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kj,$CLJS.NK,$CLJS.ji,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.f7],null)],null),mua,xua,"metabase/lib/drill_thru/zoom_in_bins.cljc",31,1,86,86,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[L8,F8,new $CLJS.h(null,
2,[$CLJS.mo,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xy,$CLJS.Fi],null),$CLJS.Xr,G8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.binning]\n          \n\n  Return a drill thru that 'zooms in' on a breakout that uses `:binning` if applicable.\n  See [[metabase.lib.drill-thru.zoom-in-bins]] docstring for more information.",
$CLJS.n(B8)?B8.H:null])),I8,!0],null)],null);
$CLJS.cva=function(){function a(d,e,f){try{return $CLJS.Wf.g($CLJS.xf,$CLJS.c1(d)?function(){var l=kua(f);return function u(t){return new $CLJS.ie(null,function(){for(var v=t;;){var x=$CLJS.y(v);if(x){var A=x,C=$CLJS.z(A),G=$CLJS.O(C),J=$CLJS.K.g(G,$CLJS.Rr),S=$CLJS.K.g(G,I8);if(x=$CLJS.y(function(X,W,da,ra,Ma,sb,Qa,Xa){return function Ta(Va){return new $CLJS.ie(null,function(mb,dc,ed,Jd){return function(){for(var hd=Va;;)if(hd=$CLJS.y(hd)){if($CLJS.wd(hd)){var Rh=$CLJS.ic(hd),sz=$CLJS.D(Rh),Jl=$CLJS.le(sz);
a:for(var Wn=0;;)if(Wn<sz){var Ar=$CLJS.id(Rh,Wn);Ar=Jd.j?Jd.j(d,e,Ar):Jd.call(null,d,e,Ar);$CLJS.n(Ar)&&Jl.add(Ar);Wn+=1}else{Rh=!0;break a}return Rh?$CLJS.oe($CLJS.qe(Jl),Ta($CLJS.jc(hd))):$CLJS.oe($CLJS.qe(Jl),null)}Jl=$CLJS.z(hd);Jl=Jd.j?Jd.j(d,e,Jl):Jd.call(null,d,e,Jl);if($CLJS.n(Jl))return $CLJS.ae(Jl,Ta($CLJS.Hc(hd)));hd=$CLJS.Hc(hd)}else return null}}(X,W,da,ra,Ma,sb,Qa,Xa),null,null)}}(v,C,G,J,S,A,x,l)($CLJS.n(function(){var X=S;return $CLJS.n(X)?l:X}())?l:new $CLJS.P(null,1,5,$CLJS.Q,[f],
null))))return $CLJS.bf.g(x,u($CLJS.Hc(v)));v=$CLJS.Hc(v)}else return null}},null,null)}(bva)}():null)}catch(l){var k=l;throw $CLJS.Th(["Error getting available drill thrus for query: ",$CLJS.p.h($CLJS.vU(k))].join(""),new $CLJS.h(null,3,[$CLJS.GE,d,$CLJS.HK,e,$CLJS.sM,f],null),k);}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();
$CLJS.dva=function(){function a(d,e){return b.j?b.j(d,-1,e):b.call(null,d,-1,e)}var b=null,c=function(){function d(f,k,l,m){var t=null;if(3<arguments.length){t=0;for(var u=Array(arguments.length-3);t<u.length;)u[t]=arguments[t+3],++t;t=new $CLJS.w(u,0,null)}return e.call(this,f,k,l,t)}function e(f,k,l,m){var t=$CLJS.Mz($CLJS.Bx);$CLJS.n($CLJS.Lz("metabase.lib.drill-thru",t))&&$CLJS.Kz("metabase.lib.drill-thru",t,$CLJS.wV("Applying drill thru: %s",$CLJS.H([$CLJS.xV(new $CLJS.h(null,4,[$CLJS.GE,f,$CLJS.HK,
k,uua,l,$CLJS.Kr,m],null))])),null);return $CLJS.Ne.M($CLJS.$5,f,k,l,m)}d.A=3;d.B=function(f){var k=$CLJS.z(f);f=$CLJS.B(f);var l=$CLJS.z(f);f=$CLJS.B(f);var m=$CLJS.z(f);f=$CLJS.Hc(f);return e(k,l,m,f)};d.l=e;return d}();b=function(d,e,f,k){switch(arguments.length){case 2:return a.call(this,d,e);default:var l=null;if(3<arguments.length){l=0;for(var m=Array(arguments.length-3);l<m.length;)m[l]=arguments[l+3],++l;l=new $CLJS.w(m,0,null)}return c.l(d,e,f,l)}throw Error("Invalid arity: "+arguments.length);
};b.A=3;b.B=c.B;b.g=a;b.l=c.l;return b}();