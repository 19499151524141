var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.cache.js");require("./metabase.lib.convert.js");require("./metabase.lib.core.js");require("./metabase.lib.equality.js");require("./metabase.lib.field.js");require("./metabase.lib.join.js");require("./metabase.lib.js.metadata.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.util.js");require("./metabase.lib.order_by.js");require("./metabase.lib.stage.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.js.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.time.js");require("./metabase.util.log.js");require("./metabase.util.memoize.js");
'use strict';var $ya=function(a,b){const c={};for(const d in a)b.call(void 0,a[d],d,a)&&(c[d]=a[d]);return c},aza=function(a,b){const c={};for(const d in a)c[d]=b.call(void 0,a[d],d,a);return c},bza=function(a,b,c){if(b in a)return a[b];c=c();return a[b]=c},cza=function(a,b,c,d){return $CLJS.j_(a,b,c,d,$CLJS.Dk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.fZ,U9],null),new $CLJS.h(null,1,[$CLJS.eZ,!0],null)])))},dza=function(a){var b=$CLJS.O(a);a=$CLJS.K.g(b,$CLJS.tA);b=$CLJS.K.g(b,$CLJS.qi);a=$CLJS.n(b)?
b:a;return $CLJS.Dz(a,$CLJS.Cj)||$CLJS.Dz(a,$CLJS.Yi)},eza=function(a,b,c,d){var e=$CLJS.Wf.j($CLJS.N,$CLJS.Hl(function(f,k){return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),d);return function l(k){return new $CLJS.ie(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.wd(m)){var t=$CLJS.ic(m),u=$CLJS.D(t),v=$CLJS.le(u);a:for(var x=0;;)if(x<u){var A=$CLJS.id(t,x);A=$CLJS.X1.v(a,b,A,d);A=$CLJS.K.j(e,A,-1);v.add(A);x+=1}else{t=!0;break a}return t?$CLJS.oe($CLJS.qe(v),l($CLJS.jc(m))):$CLJS.oe($CLJS.qe(v),
null)}v=$CLJS.z(m);v=$CLJS.X1.v(a,b,v,d);return $CLJS.ae($CLJS.K.j(e,v,-1),l($CLJS.Hc(m)))}return null}},null,null)}(c)},V9=function(a,b){return new $CLJS.uh(function(){try{return $CLJS.Q9.g(a,b)}catch(e){if(e instanceof Error){var c=e,d=$CLJS.Mz($CLJS.Cx);$CLJS.n($CLJS.Lz("metabase.lib.js.metadata",d))&&(c instanceof Error?$CLJS.Kz("metabase.lib.js.metadata",d,$CLJS.wV("Error parsing %s objects: %s",$CLJS.H([a,$CLJS.vU(c)])),c):$CLJS.Kz("metabase.lib.js.metadata",d,$CLJS.wV(c,$CLJS.H(["Error parsing %s objects: %s",
a,$CLJS.vU(c)])),null));return null}throw e;}})},fza=function(a,b){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.wd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.id(k,x);$CLJS.I(A,0,null);var C=$CLJS.I(A,1,null),G=function(){var J=C;return null==J?null:$CLJS.q(J)}();$CLJS.n(function(){var J=G;return $CLJS.n(J)?$CLJS.E.g($CLJS.bK.h(G),b):J}())&&m.add(G);x+=1}else return!0}()?$CLJS.oe($CLJS.qe(m),
e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}var t=$CLJS.z(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.bK.h(v),b):x}()))return $CLJS.ae(v,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.SD.h(a);return null==d?null:$CLJS.q(d)}())},gza=function(a,b){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.wd(f)){var k=
$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.id(k,x);$CLJS.I(A,0,null);var C=$CLJS.I(A,1,null),G=function(){var J=C;return null==J?null:$CLJS.q(J)}();$CLJS.n(function(){var J=G;return $CLJS.n(J)?$CLJS.E.g($CLJS.bK.h(G),b):J}())&&m.add(G);x+=1}else return!0}()?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}var t=$CLJS.z(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=
v;return $CLJS.n(x)?$CLJS.E.g($CLJS.bK.h(v),b):x}()))return $CLJS.ae(v,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.K9.h(a);return null==d?null:$CLJS.q(d)}())},hza=function(a,b){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.wd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.id(k,x);$CLJS.I(A,0,null);var C=$CLJS.I(A,1,null),G=function(){var J=C;
return null==J?null:$CLJS.q(J)}();$CLJS.n(function(){var J=G;return $CLJS.n(J)?$CLJS.E.g($CLJS.bK.h(G),b):J}())&&m.add(G);x+=1}else return!0}()?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}var t=$CLJS.z(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.bK.h(v),b):x}()))return $CLJS.ae(v,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null},null,null)}(function(){var d=null==
a?null:$CLJS.L9.h(a);return null==d?null:$CLJS.q(d)}())},W9=function(a,b,c,d){this.fg=a;this.Fg=b;this.metadata=c;this.mi=d;this.C=393216;this.I=0},iza=function(a,b){var c=new $CLJS.h(null,6,[$CLJS.Aya,V9($CLJS.XO,b),$CLJS.N9,V9($CLJS.Pj,b),$CLJS.SD,V9($CLJS.kF,b),$CLJS.sya,V9($CLJS.yM,b),$CLJS.K9,V9($CLJS.fF,b),$CLJS.L9,V9($CLJS.IE,b)],null),d=$CLJS.Mz($CLJS.Bx);$CLJS.n($CLJS.Lz("metabase.lib.js.metadata",d))&&$CLJS.Kz("metabase.lib.js.metadata",d,$CLJS.xv.l($CLJS.H(["Created metadata provider for metadata"])),
null);return new W9(a,b,c,$CLJS.N)},jza=function(a){return $CLJS.Ua(a)?$ya(a,function(b){return void 0!==b}):a},kza=function(a){return $CLJS.gs($CLJS.My(aza(a,function(b){return jza(b)})),function(b){return $CLJS.ok.j($CLJS.Ez(b,$CLJS.mh),$CLJS.Vi,$CLJS.mh)})},X9=function(a,b){if($CLJS.xY(b))a=b;else{var c=$CLJS.p.h(a);a=$CLJS.j_(c,b,b,$CLJS.Qe(iza,a),new $CLJS.h(null,2,[$CLJS.fZ,$CLJS.i_,$CLJS.eZ,!0],null))}return a},lza=function(a){a=$CLJS.$z(a);const b=a.length-1;a=0<=b&&a.indexOf("?",b)==b?["is-",
$CLJS.Gz(a,/\?$/,"")].join(""):a;return $CLJS.GA.h?$CLJS.GA.h(a):$CLJS.GA.call(null,a)},$9=function(a){return $CLJS.td(a)?Y9.h?Y9.h(a):Y9.call(null,a):$CLJS.sd(a)?Z9.h?Z9.h(a):Z9.call(null,a):a instanceof $CLJS.M?$CLJS.$z(a):a},mza=function(a,b){return $CLJS.VV($CLJS.VV((0,$CLJS.EX)($CLJS.T9($CLJS.My(a))),$CLJS.lP,function(c){return $CLJS.Dk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.IP,$CLJS.N],null),c]))}),$CLJS.GE,function(c){var d=function(){var e=$CLJS.SD.h(c);return $CLJS.n(e)?e:function l(k){return new $CLJS.ie(null,
function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.wd(m)){var t=$CLJS.ic(m),u=$CLJS.D(t),v=$CLJS.le(u);a:for(var x=0;;)if(x<u){var A=$CLJS.id(t,x);v.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,A,null],null));x+=1}else{t=!0;break a}return t?$CLJS.oe($CLJS.qe(v),l($CLJS.jc(m))):$CLJS.oe($CLJS.qe(v),null)}v=$CLJS.z(m);return $CLJS.ae(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,v,null],null),l($CLJS.Hc(m)))}return null}},null,null)}(b)}();return $CLJS.R.j(c,$CLJS.SD,$CLJS.l8(d))})},nza=function(a,b){function c(l,
m){l=$CLJS.Ra($CLJS.tA.h(m))?$CLJS.ek.g(l,$CLJS.tA):l;m=$CLJS.Ra($CLJS.qi.h(m))?$CLJS.ek.g(l,$CLJS.qi):l;return $CLJS.Be(m)}var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var f=$CLJS.I(b,0,null),k=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return $CLJS.E.g(new $CLJS.P(null,3,5,$CLJS.Q,[d,e,c(a,b)],null),new $CLJS.P(null,3,5,$CLJS.Q,[f,k,c(b,a)],null))},oza=function(a,b){var c=$CLJS.HV(a,b),d=$CLJS.KV();return $CLJS.re($CLJS.cf.g(function(e){return $CLJS.ok.j($CLJS.R.j(e,$CLJS.OY,!0),$CLJS.T,
d)},$CLJS.Y_.j(a,b,c)))},pza=function(a){return $CLJS.n(function(){var b=$CLJS.z(a),c=new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.IE,null,$CLJS.$E,null,$CLJS.fF,null],null),null);return c.h?c.h(b):c.call(null,b)}())?$CLJS.sk.j(a,0,2):$CLJS.ok.v(a,2,$CLJS.gs,function(b){return $CLJS.he(b)?$CLJS.$z(b):b})},a$=function(a){return $CLJS.$X.h($CLJS.ok.j($CLJS.Ly(a,$CLJS.H([$CLJS.li,!0])),0,$CLJS.mh))},qza=function(a){var b=$CLJS.Ua(a)?a:null;return $CLJS.n(b)?(a=$CLJS.S9.h?$CLJS.S9.h(b):$CLJS.S9.call(null,
b),$CLJS.E.g($CLJS.NJ.h(a),$CLJS.mK)?(b=a$(b.field_ref),$CLJS.R.j(a,$CLJS.F0,$CLJS.fd(b))):a):a},b$=function(a,b){var c=null==a?null:$CLJS.z(a);c=null==c?null:$CLJS.E.g(c,$CLJS.$E);return $CLJS.n(c)?$CLJS.R.j(b,$CLJS.F0,$CLJS.fd(a)):b},c$=function(a,b,c){b=$CLJS.A9.l($CLJS.H([a,b]));a=$CLJS.Wf.j($CLJS.N,$CLJS.Hl(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);l=$CLJS.K.g(l,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,l],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.Hl(function(k,l){$CLJS.I(l,
0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);l=$CLJS.K.g(l,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[l,k],null)}),b);var d=$CLJS.ZX,e=$CLJS.YX;$CLJS.ZX=a;$CLJS.YX=b;try{var f=function(){var k=c.field_ref;return $CLJS.n(k)?a$(k):null}();return b$(f,$CLJS.S9.h?$CLJS.S9.h(c):$CLJS.S9.call(null,c))}finally{$CLJS.YX=e,$CLJS.ZX=d}},rza=function(a){return function(b){var c=function(){var e=a.h?a.h(b):a.call(null,b);return $CLJS.S9.h?$CLJS.S9.h(e):$CLJS.S9.call(null,e)}(),d=function(){var e=$CLJS.t1.h(c);
return $CLJS.n(e)?a$(e):null}();return new $CLJS.h(null,3,[$CLJS.Di,b$(d,c),$CLJS.DK,d,$CLJS.hj,b.value],null)}},U9=function U9(a){switch(arguments.length){case 0:return U9.o();case 3:return U9.j(arguments[0],arguments[1],arguments[2]);case 4:return U9.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};U9.o=function(){return{}};U9.j=function(a,b,c){a=$CLJS.Aa(a,b);return $CLJS.n(a)?a.get(c):null};
U9.v=function(a,b,c,d){return bza(a,b,function(){return new WeakMap}).set(c,d)};U9.A=4;$CLJS.g=W9.prototype;$CLJS.g.P=function(a,b){return new W9(this.fg,this.Fg,this.metadata,b)};$CLJS.g.O=function(){return this.mi};$CLJS.g.ni=$CLJS.yc;$CLJS.g.vg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.K9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.K.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.wg=function(a,b){return gza(this.metadata,b)};
$CLJS.g.Ag=function(a,b){a=this.metadata;a=null==a?null:$CLJS.N9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.K.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.ug=function(a,b){return fza(this.metadata,b)};$CLJS.g.tg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.SD.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.K.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.yg=function(a,b){return hza(this.metadata,b)};$CLJS.g.zg=function(a,b){return $CLJS.D9($CLJS.D9(this.Fg,"settings"),$CLJS.Xg(b))};
$CLJS.g.rg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.sya.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.K.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.xg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.L9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.K.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.sg=function(){var a=this.metadata,b=this.fg;a=null==a?null:$CLJS.Aya.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.K.g(a,b);return null==b?null:$CLJS.q(b)};
var sza=new $CLJS.M(null,"can-save","can-save",-462869392),tza=new $CLJS.M(null,"can-run","can-run",1635004421);var d$=function d$(a){switch(arguments.length){case 1:return d$.h(arguments[0]);case 2:return d$.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.lib.js.extract_template_tags",d$);d$.h=function(a){return d$.g(a,$CLJS.N)};d$.g=function(a,b){return $CLJS.Gk($CLJS.Kxa.l($CLJS.H([a,kza(b)])))};d$.A=2;
var e$=function e$(a){switch(arguments.length){case 2:return e$.g(arguments[0],arguments[1]);case 3:return e$.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.lib.js.query",e$);e$.g=function(a,b){return $CLJS.Rxa.l($CLJS.H([a,b]))};e$.j=function(a,b,c){return cza($CLJS.p.h(a),b,c,function(d){return $CLJS.Rxa.l($CLJS.H([b,$CLJS.$X.h((0,$CLJS.EX)($CLJS.I1($CLJS.H7(d),$CLJS.Vi,$CLJS.GE)))]))})};e$.A=3;
var f$=function f$(a){return $CLJS.he(a)?[$CLJS.de(a),"/",$CLJS.Xg(a)].join(""):$CLJS.td(a)?$CLJS.gs(a,f$):$CLJS.sd(a)?$CLJS.cf.g(f$,a):a},Y9=$CLJS.Zz(function(a){return $CLJS.cb(function(b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);d=lza(d);c=$9.h?$9.h(c):$9.call(null,c);b[d]=c;return b},{},a)}),Z9=$CLJS.Zz(function(a){return $CLJS.re($CLJS.cf.g($9,a))}),g$=function g$(a){switch(arguments.length){case 1:return g$.h(arguments[0]);case 2:return g$.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",
$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.lib.js.order_by_clause",g$);g$.h=function(a){return g$.g(a,$CLJS.hF)};g$.g=function(a,b){return $CLJS.Nxa.l($CLJS.H([$CLJS.C9.l($CLJS.H([$CLJS.Ly(a,$CLJS.H([$CLJS.li,!0]))])),$CLJS.mh.h(b)]))};g$.A=2;var h$=function h$(a){switch(arguments.length){case 2:return h$.g(arguments[0],arguments[1]);case 3:return h$.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.xa("metabase.lib.js.available_binning_strategies",h$);h$.g=function(a,b){return $CLJS.re($CLJS.B9.l($CLJS.H([a,b])))};h$.j=function(a,b,c){return $CLJS.re($CLJS.B9.l($CLJS.H([a,b,c])))};h$.A=3;var i$=function i$(a){switch(arguments.length){case 2:return i$.g(arguments[0],arguments[1]);case 3:return i$.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.lib.js.available_temporal_buckets",i$);
i$.g=function(a,b){return $CLJS.re($CLJS.gya.l($CLJS.H([a,b])))};i$.j=function(a,b,c){return $CLJS.re($CLJS.gya.l($CLJS.H([a,b,c])))};i$.A=3;
var j$=function j$(a,b){return $CLJS.vd(a)&&$CLJS.vd(b)&&$CLJS.E.l($CLJS.z(a),$CLJS.z(b),$CLJS.H([$CLJS.kF]))?nza(a,b):$CLJS.td(a)&&$CLJS.td(b)?$CLJS.E.g($CLJS.Wg($CLJS.Ng(a)),$CLJS.Wg($CLJS.Ng(b)))&&$CLJS.Le(function(d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);e=$CLJS.K.g(b,e);return j$.g?j$.g(d,e):j$.call(null,d,e)},a):$CLJS.sd(a)&&$CLJS.sd(b)?$CLJS.E.g($CLJS.D(a),$CLJS.D(b))&&$CLJS.Le($CLJS.ll,$CLJS.cf.j(j$,a,b)):$CLJS.E.g(a,b)},k$=function k$(a){switch(arguments.length){case 2:return k$.g(arguments[0],
arguments[1]);case 3:return k$.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.lib.js.query_EQ_",k$);k$.g=function(a,b){return k$.j(a,b,null)};k$.j=function(a,b,c){a=mza(a,c);b=mza(b,c);return j$(a,b)};k$.A=3;var l$=function l$(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return l$.l(arguments[0],arguments[1],2<c.length?new $CLJS.w(c.slice(2),0,null):null)};
$CLJS.xa("metabase.lib.js.filter_clause",l$);l$.l=function(a,b,c){return $CLJS.Ne.v($CLJS.Zwa,a,b,c)};l$.A=2;l$.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return this.l(b,a,c)};var m$=function m$(a){switch(arguments.length){case 3:return m$.j(arguments[0],arguments[1],arguments[2]);case 4:return m$.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.xa("metabase.lib.js.with_different_database",m$);m$.j=function(a,b,c){return m$.v(a,b,c,null)};m$.v=function(a,b,c,d){return $CLJS.Ixa.l($CLJS.H([a,X9(b,c),$CLJS.Ly(d,$CLJS.H([$CLJS.li,!0]))]))};m$.A=4;var uza=rza(function(a){return a.col}),vza=rza(function(a){return a.column}),n$=function n$(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return n$.l(arguments[0],arguments[1],arguments[2],3<c.length?new $CLJS.w(c.slice(3),0,null):null)};
$CLJS.xa("metabase.lib.js.drill_thru",n$);n$.l=function(a,b,c,d){return $CLJS.Ne.M($CLJS.Cwa,a,b,c,d)};n$.A=3;n$.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return this.l(b,a,c,d)};
module.exports={returned_columns:function(a,b){return $CLJS.k_($CLJS.mh.g("returned-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return oza(a,b)})},legacy_expression_for_expression_clause:function(a,b,c){b=$CLJS.A9.l($CLJS.H([a,b]));a=$CLJS.Wf.j($CLJS.N,$CLJS.Hl(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);l=$CLJS.K.g(l,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,l],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.Hl(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);
l=$CLJS.K.g(l,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[l,k],null)}),b);var d=$CLJS.ZX,e=$CLJS.YX;$CLJS.ZX=a;$CLJS.YX=b;try{var f=$CLJS.PX.h(c);return $CLJS.Gk(function(){var k;if(k=$CLJS.vd(f)){k=$CLJS.z(f);var l=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.hj,null,$CLJS.xL,null],null),null);k=l.h?l.h(k):l.call(null,k)}return $CLJS.n(k)?$CLJS.K.g(f,1):f}())}finally{$CLJS.YX=e,$CLJS.ZX=d}},drop_stage_if_empty:function(a){return $CLJS.bya.l($CLJS.H([a]))},extract_template_tags:d$,engine:function(a){return $CLJS.Xg($CLJS.Dxa.l($CLJS.H([a])))},
filter_operator:function(a,b,c){return $CLJS.$wa.l($CLJS.H([a,b,c]))},binning:function(a){return $CLJS.rwa.l($CLJS.H([a]))},query:e$,columns_group_columns:function(a){return $CLJS.re($CLJS.xwa.l($CLJS.H([a])))},join_fields:function(a){a=$CLJS.nxa.l($CLJS.H([a]));return a instanceof $CLJS.M?$CLJS.$z(a):$CLJS.re(a)},aggregate:function(a,b,c){return $CLJS.mwa.l($CLJS.H([a,b,$CLJS.Ly(c,$CLJS.H([$CLJS.li,!0]))]))},join_conditions:function(a){return $CLJS.re($CLJS.mxa.l($CLJS.H([a])))},query_EQ_:k$,temporal_bucket:function(a){return $CLJS.hya.l($CLJS.H([a]))},
available_drill_thrus:function(a,b,c,d,e,f){var k=$CLJS.A9.l($CLJS.H([a,b])),l=$CLJS.Wf.j($CLJS.N,$CLJS.Hl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.K.g(x,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),k);k=$CLJS.Wf.j($CLJS.N,$CLJS.Hl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.K.g(x,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),k);var m=$CLJS.ZX,t=$CLJS.YX;$CLJS.ZX=l;$CLJS.YX=k;try{var u=function(){var v=$CLJS.n(c)?
c.field_ref:c;return $CLJS.n(v)?a$(v):null}();return $CLJS.re($CLJS.Bwa.l($CLJS.H([a,b,$CLJS.Dk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.Di,$CLJS.n(c)?b$(u,$CLJS.S9.h?$CLJS.S9.h(c):$CLJS.S9.call(null,c)):null,$CLJS.DK,u,$CLJS.hj,void 0===d?null:null==d?$CLJS.PK:d],null),$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Yh,$CLJS.nk.g(uza,e)],null):null,$CLJS.n($CLJS.Be(f))?new $CLJS.h(null,1,[$CLJS.KK,$CLJS.nk.g(vza,f)],null):null]))])))}finally{$CLJS.YX=t,$CLJS.ZX=m}},has_clauses:function(a,b){return $CLJS.cya.l($CLJS.H([a,
b]))},remove_clause:function(a,b,c){return $CLJS.Vxa.l($CLJS.H([a,b,$CLJS.C9.l($CLJS.H([$CLJS.Ly(c,$CLJS.H([$CLJS.li,!0]))]))]))},source_table_or_card_id:function(a){var b=$CLJS.a_(a);if($CLJS.n(b))return b;a=$CLJS.N1(a);return null==a?null:["card__",$CLJS.p.h(a)].join("")},legacy_ref:function(a,b,c){b=$CLJS.$E.h($CLJS.HV(a,b));a=$CLJS.Wf.j($CLJS.N,$CLJS.Hl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.K.g(k,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);
b=$CLJS.Wf.j($CLJS.N,$CLJS.Hl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.K.g(k,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.ZX,e=$CLJS.YX;$CLJS.ZX=a;$CLJS.YX=b;try{return $CLJS.Gk(pza($CLJS.PX.h($CLJS.Uxa.l($CLJS.H([c])))))}finally{$CLJS.YX=e,$CLJS.ZX=d}},replace_clause:function(a,b,c,d){return $CLJS.Yxa.l($CLJS.H([a,b,$CLJS.C9.l($CLJS.H([$CLJS.Ly(c,$CLJS.H([$CLJS.li,!0]))])),$CLJS.C9.l($CLJS.H([$CLJS.Ly(d,$CLJS.H([$CLJS.li,!0]))]))]))},
drop_stage:function(a){return $CLJS.aya.l($CLJS.H([a]))},join_lhs_display_name:function(a,b,c,d){return $CLJS.oxa.l($CLJS.H([a,b,c,d]))},add_field:function(a,b,c){return $CLJS.Pwa.l($CLJS.H([a,b,c]))},segment_metadata:function(a,b){return $CLJS.j9(a,b)},pivot_types:function(a){return $CLJS.re($CLJS.cf.g($CLJS.Xg,$CLJS.Ewa.l($CLJS.H([a]))))},native_extras:function(a){return $CLJS.Gk($CLJS.Gxa.l($CLJS.H([a])))},available_temporal_buckets:i$,with_binning:function(a,b){return $CLJS.swa.l($CLJS.H([a,b]))},
append_stage:function(a){return $CLJS.$xa.l($CLJS.H([a]))},join:function(a,b,c){return $CLJS.gxa.l($CLJS.H([a,b,c]))},aggregations:function(a,b){return $CLJS.re($CLJS.A9.l($CLJS.H([a,b])))},drill_thru:n$,raw_native_query:function(a){return $CLJS.Axa.l($CLJS.H([a]))},filterable_column_operators:function(a){return $CLJS.re($CLJS.Ywa.l($CLJS.H([a])))},with_temporal_bucket:function(a,b){return $CLJS.iya.l($CLJS.H([a,b]))},valid_filter_for_QMARK_:function(a,b){return $CLJS.HZ(a)&&$CLJS.HZ(b)||$CLJS.QZ(a)&&
$CLJS.QZ(b)||$CLJS.FZ(a)&&$CLJS.FZ(b)||$CLJS.Dz($CLJS.tA.h(a),$CLJS.tA.h(b))},with_join_conditions:function(a,b){return $CLJS.vxa.l($CLJS.H([a,$CLJS.Ly(b,$CLJS.H([$CLJS.li,!0]))]))},update_lat_lon_filter:function(a,b,c,d,e){e=$CLJS.Ly(e,$CLJS.H([$CLJS.li,!0]));c=c$(a,b,c);d=c$(a,b,d);return $CLJS.cxa.l($CLJS.H([a,b,c,d,e]))},available_metrics:function(a,b){return $CLJS.re($CLJS.yxa.l($CLJS.H([a,b])))},with_different_database:m$,with_fields:function(a,b,c){return $CLJS.Twa.l($CLJS.H([a,b,c]))},is_column_metadata:function(a){return $CLJS.td(a)&&
$CLJS.E.g($CLJS.LJ,$CLJS.Vs.h(a))},filter_args_display_name:function(a,b,c){return $CLJS.Owa.l($CLJS.H([a,b,c]))},join_clause:function(a,b){return $CLJS.hxa.l($CLJS.H([a,b]))},aggregation_clause:function(a,b){return void 0===b?$CLJS.z9.l($CLJS.H([a])):$CLJS.z9.l($CLJS.H([a,b]))},external_op:function(a){a=$CLJS.zwa.l($CLJS.H([a]));var b=$CLJS.O(a);a=$CLJS.K.g(b,$CLJS.tD);var c=$CLJS.K.g(b,$CLJS.Qi);b=$CLJS.K.g(b,$CLJS.Kr);return{operator:a,options:$CLJS.Gk(c),args:$CLJS.re(b)}},with_join_fields:function(a,
b){return $CLJS.txa.l($CLJS.H([a,"string"===typeof b?$CLJS.mh.h(b):b]))},visible_columns:function(a,b){return $CLJS.k_($CLJS.mh.g("visible-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){var c=$CLJS.HV(a,b),d=$CLJS.$_.j(a,b,c);c=$CLJS.Y_.j(a,b,c);return $CLJS.re($CLJS.k3(a,b,d,c))})},update_numeric_filter:function(a,b,c,d,e){c=c$(a,b,c);return $CLJS.dxa.l($CLJS.H([a,b,c,d,e]))},available_binning_strategies:h$,update_temporal_filter:function(a,b,c,d,e){c=c$(a,b,c);return $CLJS.exa.l($CLJS.H([a,
b,c,d,e]))},find_column_indexes_from_legacy_refs:function(a,b,c,d){var e=$CLJS.$E.h($CLJS.HV(a,b)),f=$CLJS.Wf.j($CLJS.N,$CLJS.Hl(function(u,v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.K.g(v,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[u,v],null)}),e);e=$CLJS.Wf.j($CLJS.N,$CLJS.Hl(function(u,v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.K.g(v,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null)}),e);var k=$CLJS.ZX,l=$CLJS.YX;$CLJS.ZX=f;$CLJS.YX=e;try{var m=
$CLJS.nk.g(qza,c),t=$CLJS.cf.g(a$,d);return $CLJS.re(eza(a,b,t,m))}finally{$CLJS.YX=l,$CLJS.ZX=k}},expression:function(a,b,c,d){return $CLJS.Hwa.l($CLJS.H([a,b,c,d]))},describe_relative_datetime:function(a,b){a="string"===typeof a?$CLJS.mh.h(a):a;b="string"===typeof b?$CLJS.mh.h(b):b;return $CLJS.fya.l($CLJS.H([a,b]))},order_bys:function(a,b){return $CLJS.re($CLJS.Oxa.l($CLJS.H([a,b])))},display_info:function(a,b,c){return $CLJS.k_($CLJS.mh.g("display-info-outer",["stage-",$CLJS.p.h(b)].join("")),
c,function(d){return $9($CLJS.wxa.l($CLJS.H([$CLJS.D3(a,b),b,d])))})},can_run:function(a){return $CLJS.k_(tza,a,function(){return $CLJS.Pxa.l($CLJS.H([a]))})},fieldable_columns:function(a,b){return $CLJS.k_($CLJS.mh.g("fieldable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.re($CLJS.Qwa.l($CLJS.H([a,b])))})},template_tags:function(a){return $CLJS.Gk($CLJS.Cxa.l($CLJS.H([a])))},selected_aggregation_operators:function(a,b){return $CLJS.re($CLJS.qwa.l($CLJS.H([$CLJS.y(a),b])))},
order_by_clause:g$,filterable_columns:function(a,b){return $CLJS.k_($CLJS.mh.g("filterable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.re($CLJS.Xwa.l($CLJS.H([a,b])))})},join_condition_rhs_columns:function(a,b,c,d,e){return $CLJS.re($CLJS.kxa.l($CLJS.H([a,b,c,d,e])))},join_condition_update_temporal_bucketing:function(a,b,c,d){return $CLJS.lxa.l($CLJS.H([a,b,c,d]))},filters:function(a,b){return $CLJS.re($CLJS.Wwa.l($CLJS.H([a,b])))},join_condition_lhs_columns:function(a,b,
c,d,e){return $CLJS.re($CLJS.ixa.l($CLJS.H([a,b,c,d,e])))},table_or_card_metadata:function(a,b){return $CLJS.k1(a,b)},with_expression_name:function(a,b){return $CLJS.Kwa.l($CLJS.H([a,b]))},orderable_columns:function(a,b){return $CLJS.k_($CLJS.mh.g("orderable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.re($CLJS.b8.g(a,b))})},find_filter_for_legacy_filter:function(a,b,c){return $CLJS.axa.l($CLJS.H([a,b,$CLJS.Ly(c,$CLJS.H([$CLJS.li,!0]))]))},suggestedName:function(a){return $CLJS.xxa.l($CLJS.H([a]))},
find_column_for_legacy_ref:function(a,b,c,d){return $CLJS.Fwa.l($CLJS.H([a,b,c,d]))},has_write_permission:function(a){return $CLJS.Jxa.l($CLJS.H([a]))},metadataProvider:X9,filter_clause:l$,fields:function(a,b){return $CLJS.re($CLJS.Rwa.l($CLJS.H([a,b])))},dependent_metadata:function(a){return $CLJS.re($CLJS.cf.g($CLJS.Gk,$CLJS.Lwa.l($CLJS.H([a]))))},available_aggregation_operators:function(a,b){return $CLJS.re($CLJS.pwa.l($CLJS.H([a,b])))},breakouts:function(a,b){return $CLJS.re($CLJS.wwa.l($CLJS.H([a,
b])))},joinable_columns:function(a,b,c){return $CLJS.re($CLJS.qxa.l($CLJS.H([a,b,c])))},group_columns:function(a){return $CLJS.re($CLJS.ywa.l($CLJS.H([a])))},describe_temporal_unit:function(a,b){b="string"===typeof b?$CLJS.mh.h(b):b;return $CLJS.dya.l($CLJS.H([a,b]))},breakout_column:function(a,b,c){return $CLJS.uwa.l($CLJS.H([a,b,c]))},joins:function(a,b){return $CLJS.re($CLJS.rxa.l($CLJS.H([a,b])))},native_query:function(a,b,c){return $CLJS.zxa.l($CLJS.H([X9(a,b),c]))},available_join_strategies:function(a,
b){return $CLJS.re($CLJS.fxa.l($CLJS.H([a,b])))},legacy_query:function(a){return $CLJS.Gk.l(f$($CLJS.PX.h(a)),$CLJS.H([$CLJS.Rj,$CLJS.$z]))},stage_count:function(a){return $CLJS.Sxa.l($CLJS.H([a]))},expression_clause:function(a,b,c){return $CLJS.C9.l($CLJS.H([$CLJS.Mwa.l($CLJS.H([$CLJS.mh.h(a),b,$CLJS.Ly(c,$CLJS.H([$CLJS.li,!0]))]))]))},find_matching_column:function(a,b,c,d){return $CLJS.Gwa.l($CLJS.H([a,b,c,d]))},with_native_extras:function(a,b){return $CLJS.Hxa.l($CLJS.H([a,$CLJS.Ly(b,$CLJS.H([$CLJS.li,
!0]))]))},join_strategy:function(a){return $CLJS.pxa.l($CLJS.H([a]))},picker_info:function(a,b){var c=$CLJS.Vs.h(b);switch(c instanceof $CLJS.M?c.S:null){case "metadata/table":return{databaseId:$CLJS.XO.h(a),tableId:$CLJS.Ki.h(b)};case "metadata/card":return{databaseId:$CLJS.XO.h(a),tableId:["card__",$CLJS.p.h($CLJS.Ki.h(b))].join(""),cardId:$CLJS.Ki.h(b),isModel:$CLJS.FY.h(b)};default:return a=$CLJS.Mz($CLJS.Ex),$CLJS.n($CLJS.Lz("metabase.lib.js",a))&&$CLJS.Kz("metabase.lib.js",a,$CLJS.xv.l($CLJS.H(["Cannot provide picker-info for",
$CLJS.Vs.h(b)])),null),null}},expression_parts:function(a,b,c){a=$CLJS.Nwa.l($CLJS.H([a,b,c]));return $CLJS.wU(function(d){if($CLJS.td(d)&&$CLJS.E.g($CLJS.M8,$CLJS.Vs.h(d))){var e=$CLJS.O(d);d=$CLJS.K.g(e,$CLJS.tD);var f=$CLJS.K.g(e,$CLJS.Qi);e=$CLJS.K.g(e,$CLJS.Kr);return{operator:$CLJS.Xg(d),options:$CLJS.Gk($CLJS.Fl(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sF,$CLJS.Nk],null))),args:$CLJS.re($CLJS.cf.g(function(k){return k instanceof $CLJS.M?$CLJS.$z(k):k},e))}}return d},a)},expression_clause_for_legacy_expression:function(a,
b,c){b=$CLJS.A9.l($CLJS.H([a,b]));a=$CLJS.Wf.j($CLJS.N,$CLJS.Hl(function(l,m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.O(m);m=$CLJS.K.g(m,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[l,m],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.Hl(function(l,m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.O(m);m=$CLJS.K.g(m,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[m,l],null)}),b);var d=$CLJS.ZX,e=$CLJS.YX;$CLJS.ZX=a;$CLJS.YX=b;try{var f=$CLJS.Ly(c,$CLJS.H([$CLJS.li,!0])),k=$CLJS.z($CLJS.FX(new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.GE,$CLJS.$E],null),new $CLJS.P(null,1,5,$CLJS.Q,[f],null)));return $CLJS.$X.h(k)}finally{$CLJS.YX=e,$CLJS.ZX=d}},format_relative_date_range:function(a,b,c,d,e){b=$CLJS.mh.h(b);d=null==d?null:$CLJS.mh.h(d);e=$CLJS.Ly(e,$CLJS.H([$CLJS.li,!0]));return $CLJS.m2((0,$CLJS.OH)(),a,b,c,d,e)},aggregation_operator_columns:function(a){return $CLJS.re($CLJS.owa.l($CLJS.H([a])))},find_filterable_column_for_legacy_ref:function(a,b,c){return $CLJS.bxa.l($CLJS.H([a,b,c]))},can_save:function(a){return $CLJS.k_(sza,
a,function(){return $CLJS.Qxa.l($CLJS.H([a]))})},available_segments:function(a,b){return $CLJS.re($CLJS.Zxa.l($CLJS.H([a,b])))},join_condition_operators:function(a,b,c,d){return $CLJS.re($CLJS.jxa.l($CLJS.H([a,b,c,d])))},aggregation_column:function(a,b,c){return $CLJS.nwa.l($CLJS.H([a,b,c]))},expressionable_columns:function(a,b,c){return $CLJS.k_($CLJS.mh.g("expressionable-columns",["stage-",$CLJS.p.h(b),"-",$CLJS.p.h(c)].join("")),a,function(){return $CLJS.re($CLJS.Jwa.l($CLJS.H([a,b,c])))})},filter:function(a,
b,c){return $CLJS.Uwa.l($CLJS.H([a,b,$CLJS.Ly(c,$CLJS.H([$CLJS.li,!0]))]))},metric_metadata:function(a,b){return $CLJS.T3(a,b)},change_direction:function(a,b){return $CLJS.Lxa.l($CLJS.H([a,b]))},pivot_columns_for_type:function(a,b){return $CLJS.re($CLJS.Dwa.l($CLJS.H([a,$CLJS.mh.h(b)])))},filter_drill_details:function(a){var b=$CLJS.O(a);a=$CLJS.K.g(b,$CLJS.GE);var c=$CLJS.K.g(b,$CLJS.Di),d=$CLJS.K.g(b,$CLJS.HK);b=$CLJS.K.g(b,$CLJS.hj);return{column:c,query:a,stageIndex:d,value:$CLJS.E.g(b,$CLJS.PK)?
null:b}},remove_field:function(a,b,c){return $CLJS.Swa.l($CLJS.H([a,b,c]))},with_template_tags:function(a,b){return $CLJS.Exa.l($CLJS.H([a,kza(b)]))},expressions:function(a,b){return $CLJS.re($CLJS.Iwa.l($CLJS.H([a,b])))},suggested_join_conditions:function(a,b,c){return $CLJS.re($CLJS.sxa.l($CLJS.H([a,b,c])))},with_join_strategy:function(a,b){return $CLJS.uxa.l($CLJS.H([a,b]))},order_by:function(a,b,c,d){return $CLJS.Mxa.l($CLJS.H([a,b,c,$CLJS.mh.h(d)]))},field_values_search_info:function(a,b){var c=
$CLJS.ok.j;if($CLJS.n(b)){var d=$CLJS.Ki.h(b),e=$CLJS.Ki.h;var f=$CLJS.NZ(b)?b:null;$CLJS.n(f)?a=f:(f=$CLJS.Bz(b,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$J,$CLJS.WJ],null)),a=$CLJS.n(f)?$CLJS.b1(a,f):null,a=$CLJS.n(a)?a:b);a=dza(a)?a:null;e=e.call($CLJS.Ki,a);d=$CLJS.ol(d)?d:null;e=$CLJS.ol(e)?e:null;$CLJS.n(b)?(b=$CLJS.O(b),a=$CLJS.K.g(b,$CLJS.WY),b=$CLJS.E.g(a,$CLJS.YY)?$CLJS.gj:$CLJS.n(a)?a:dza(b)?$CLJS.CY:$CLJS.cz):b=$CLJS.cz;b=new $CLJS.h(null,3,[$CLJS.WJ,d,$CLJS.y7,e,$CLJS.WY,b],null)}else b=null;
return $CLJS.Gk($CLJS.Ez(c.call($CLJS.ok,b,$CLJS.WY,$CLJS.Xg),lza))},describe_temporal_interval:function(a,b){a="string"===typeof a?$CLJS.mh.h(a):a;b="string"===typeof b?$CLJS.mh.h(b):b;return $CLJS.eya.l($CLJS.H([a,b]))},breakout:function(a,b,c){return $CLJS.twa.l($CLJS.H([a,b,$CLJS.Uxa.l($CLJS.H([c]))]))},database_id:function(a){return $CLJS.Awa.l($CLJS.H([a]))},required_native_extras:function(a,b){return $CLJS.re($CLJS.cf.g($CLJS.$z,$CLJS.Fxa.l($CLJS.H([X9(a,b)]))))},remove_join:function(a,b,c){return $CLJS.Wxa.l($CLJS.H([a,
b,c]))},joined_thing:function(a,b){return $CLJS.x3(a,b)},legacy_column__GT_metadata:c$,with_native_query:function(a,b){return $CLJS.Bxa.l($CLJS.H([a,b]))},breakoutable_columns:function(a,b){return $CLJS.k_($CLJS.mh.g("breakoutable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.re($CLJS.vwa.l($CLJS.H([a,b])))})},rename_join:function(a,b,c,d){return $CLJS.Xxa.l($CLJS.H([a,b,c,d]))},with_different_table:function(a,b){return $CLJS.Txa.l($CLJS.H([a,b]))}};