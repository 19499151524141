var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var o7,p7,q7,ota,pta,qta,t7,u7,w7,x7,rta,sta,tta,z7,A7,B7,uta,vta;o7=function(a){var b=new $CLJS.h(null,3,[$CLJS.yD,$CLJS.p.h($CLJS.ID()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.qi,$CLJS.zz($CLJS.qi,$CLJS.tA)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,b,$CLJS.zz($CLJS.pD,$CLJS.T)(a)],null)};p7=function(a){return $CLJS.R.j(a,$CLJS.Vs,$CLJS.G4)};q7=function(a,b){return $CLJS.E.g($CLJS.kD.h(a),$CLJS.Fl(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CD,$CLJS.jD,$CLJS.BD],null)))};
ota=function(a){return $CLJS.S0(a,new $CLJS.h(null,1,[$CLJS.CD,$CLJS.mh],null))};
pta=function(a,b){var c=$CLJS.Me(function(e){return $CLJS.sG(function(f){return $CLJS.E.g($CLJS.K.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NY,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",c))){var d=$CLJS.gD("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([a])),$CLJS.xh.l($CLJS.H([$CLJS.nk.g($CLJS.NY,b)]))]));d instanceof Error?$CLJS.Kz("metabase.lib.field",c,$CLJS.xv(),d):$CLJS.Kz("metabase.lib.field",
c,$CLJS.xv.l($CLJS.H([d])),null)}return null};
qta=function(a,b,c){if($CLJS.n(r7))return null;var d=r7;r7=!0;try{var e=$CLJS.L1(a,b),f=$CLJS.n(e)?$CLJS.HV(a,e):$CLJS.HV(a,b),k=function(){var m=$CLJS.s7.h(f);if($CLJS.n(m))return m;m=$CLJS.Bz(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qU,$CLJS.EV],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.QU.h(f);if($CLJS.n(u))return u;u=$CLJS.hO.h(f);if($CLJS.n(u))return u;u=$CLJS.bP.h(f);return $CLJS.n(u)?u:$CLJS.SD.h(f)}())?$CLJS.$_.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",
m))){var t=$CLJS.gD("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.Kz("metabase.lib.field",m,$CLJS.xv(),t):$CLJS.Kz("metabase.lib.field",m,$CLJS.xv.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?pta(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.m3($CLJS.ek.l(l,$CLJS.Ki,$CLJS.H([$CLJS.bK,$CLJS.QV,$CLJS.LV])),null),$CLJS.T,function(){var m=$CLJS.NY.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.NJ,$CLJS.zY):l:null}finally{r7=d}};
t7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.K.g(e,$CLJS.wO);var f=$CLJS.I(c,2,null);c=$CLJS.Dk.l($CLJS.H([function(){var k=$CLJS.tA.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.tA,k],null):null}(),function(){var k=$CLJS.zz($CLJS.qi,$CLJS.tA)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.qi,k],null):null}(),function(){var k=$CLJS.wQ.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.QV,k],null):null}(),function(){var k=$CLJS.qF.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.LV,k],null):null}(),$CLJS.Bd(f)?function(){var k=$CLJS.R1(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.LJ,$CLJS.T,$CLJS.p.h(f)],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.LJ,$CLJS.T,$CLJS.p.h(f)],null):function(){var k=qta(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.LJ,$CLJS.T,$CLJS.p.h(f)],null)}()]));return $CLJS.n(d)?$CLJS.m3(c,d):c};
u7=function(a){a=$CLJS.O(a);var b=$CLJS.K.g(a,$CLJS.LV);return $CLJS.n($CLJS.n(b)?$CLJS.Dd($CLJS.pG,b):b)?$CLJS.bj:$CLJS.zz($CLJS.qi,$CLJS.tA)(a)};
$CLJS.v7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.K.g(f,$CLJS.yD),l=$CLJS.K.g(f,$CLJS.tA),m=$CLJS.K.g(f,$CLJS.wQ),t=$CLJS.K.g(f,$CLJS.qi);e=$CLJS.K.g(f,$CLJS.wO);var u=$CLJS.K.g(f,$CLJS.HM),v=$CLJS.K.g(f,$CLJS.qF),x=$CLJS.Dk.l;k=new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.LJ,$CLJS.F0,k],null);f=$CLJS.lD.h(f);a=$CLJS.n(f)?f:$CLJS.n_.j(a,b,d);c=x.call($CLJS.Dk,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.lD,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.qi,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.tA,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.LV,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.QV,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.aK,u):m;return $CLJS.n(e)?$CLJS.m3(u,e):u};w7=function(a,b,c){return $CLJS.n($CLJS.Me(function(d){return $CLJS.E.g($CLJS.ei.h(d),c)},a))?$CLJS.nk.g(function(d){var e=$CLJS.Dd(d,b)?$CLJS.ek.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.ei.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
x7=function(a){var b=$CLJS.n($CLJS.s1.h(a))?null:function(){var e=$CLJS.NJ.h(a),f=new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.QY,null,$CLJS.XY,null,$CLJS.zY,null],null),null);return f.h?f.h(e):f.call(null,e)}(),c=$CLJS.Dk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.yD,$CLJS.p.h($CLJS.ID()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.qi,u7(a)],null),function(){var e=function(){var f=$CLJS.Ra(b);return f?(f=$CLJS.Ra($CLJS.aK.h(a)))?(f=$CLJS.kk.g($CLJS.JY,$CLJS.NJ.h(a)))?$CLJS.aZ.h(a):f:f:f}();return $CLJS.n(e)?new $CLJS.h(null,
1,[$CLJS.wO,e],null):null}(),function(){var e=$CLJS.l1(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.wO,e],null):null}(),function(){var e=$CLJS.LV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.qF,e],null):null}(),function(){var e=$CLJS.PV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.PV,e],null):null}(),function(){var e=$CLJS.QV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.wQ,e],null):null}(),function(){var e=$CLJS.aK.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.HM,e],null):null}()])),
d=($CLJS.n(b)?$CLJS.zz($CLJS.NY,$CLJS.T):$CLJS.zz($CLJS.Ki,$CLJS.T))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,c,d],null)};rta=function(a,b){return $CLJS.ff(function(c){return $CLJS.E.g($CLJS.NJ.h(c),$CLJS.HY)},$CLJS.$_.v(a,b,$CLJS.HV(a,b),new $CLJS.h(null,3,[$CLJS.H_,!1,$CLJS.N_,!0,$CLJS.D_,!1],null)))};sta=new $CLJS.M("lib","simple-name","lib/simple-name",-734346338);tta=new $CLJS.M("metabase.lib.field","field-values-search-info","metabase.lib.field/field-values-search-info",-468454179);
$CLJS.y7=new $CLJS.M(null,"search-field-id","search-field-id",-699326724);z7=new $CLJS.M("metabase.lib.field","field-values-search-info.has-field-values","metabase.lib.field/field-values-search-info.has-field-values",1753198226);A7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.s7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);B7=new $CLJS.M("metabase.lib.field","simple-display-name","metabase.lib.field/simple-display-name",494797345);
uta=new $CLJS.M(null,"earliest","earliest",-1928154382);vta=new $CLJS.M(null,"latest","latest",24323665);$CLJS.T0.m(null,$CLJS.kF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mh.h(b),$CLJS.S0(c,new $CLJS.h(null,2,[$CLJS.qF,$CLJS.mh,$CLJS.wQ,ota],null)),a],null)});
var r7=!1,C7=function C7(a,b){var d=$CLJS.b1(a,A7.h(b));a=$CLJS.n(A7.h(d))?C7.g?C7.g(a,d):C7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.K.g(a,$CLJS.T),f=$CLJS.K.g(a,$CLJS.lD);return $CLJS.ok.j($CLJS.R.j($CLJS.ok.j($CLJS.R.j(b,sta,$CLJS.T.h(b)),$CLJS.T,function(k){return[$CLJS.p.h(e),".",$CLJS.p.h(k)].join("")}),B7,$CLJS.lD.h(b)),$CLJS.lD,function(k){return[$CLJS.p.h(f),": ",$CLJS.p.h(k)].join("")})};$CLJS.S_.m(null,$CLJS.LJ,function(a,b,c){return u7(c)});
$CLJS.S_.m(null,$CLJS.kF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.K.g(d,$CLJS.qF);$CLJS.I(c,2,null);c=t7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.LV,d):c;return $CLJS.T_.j(a,b,c)});$CLJS.V_.m(null,$CLJS.LJ,function(a,b,c){a=$CLJS.O(c);b=$CLJS.K.g(a,$CLJS.T);return $CLJS.R.j(a,$CLJS.T,b)});$CLJS.V_.m(null,$CLJS.kF,function(a,b,c){var d=t7(a,b,c);b=$CLJS.v7(a,b,d,c);return $CLJS.n(A7.h(b))?C7(a,b):b});
$CLJS.O_.m(null,$CLJS.LJ,function(a,b,c,d){function e(W){var da=$CLJS.l5(l,k);return $CLJS.ha.j?$CLJS.ha.j("%s: %s",W,da):$CLJS.ha.call(null,"%s: %s",W,da)}function f(W){var da=$CLJS.cA($CLJS.Gz($CLJS.Xg(u),"-"," "));return $CLJS.ha.j?$CLJS.ha.j("%s: %s",W,da):$CLJS.ha.call(null,"%s: %s",W,da)}var k=$CLJS.O(c),l=$CLJS.K.g(k,$CLJS.QV),m=$CLJS.K.g(k,$CLJS.aK),t=$CLJS.K.g(k,$CLJS.bK),u=$CLJS.K.g(k,$CLJS.ei),v=$CLJS.K.g(k,$CLJS.aZ),x=$CLJS.K.g(k,B7),A=$CLJS.K.g(k,$CLJS.T),C=$CLJS.K.g(k,$CLJS.L5),G=$CLJS.K.g(k,
$CLJS.lD),J=$CLJS.K.g(k,A7),S=$CLJS.j1.g($CLJS.xA,A);c=function(){if($CLJS.n(x))return x;if($CLJS.n($CLJS.n(J)?null==G||$CLJS.E.g(G,S):J)){a:{var W=$CLJS.O(k);var da=$CLJS.K.g(W,$CLJS.lD);W=$CLJS.K.g(W,A7);for(da=new $CLJS.Vd(null,da,null,1,null);;)if($CLJS.n(W)){W=$CLJS.b1(a,W);var ra=$CLJS.O(W);W=$CLJS.K.g(ra,$CLJS.lD);ra=$CLJS.K.g(ra,A7);da=$CLJS.Yd.g(da,W);W=ra}else break a}da=$CLJS.Le($CLJS.Sa,da)?$CLJS.hs(": ",da):null}else da=null;return $CLJS.n(da)?da:$CLJS.n(G)?G:"string"===typeof A?S:$CLJS.p.h(A)}();
var X=$CLJS.E.g(d,$CLJS.o_)&&-1==c.indexOf(" → ")?function(){if($CLJS.n(m)){var W=$CLJS.b1(a,m);$CLJS.n(W)?W=$CLJS.i3($CLJS.lD.h($CLJS.q_.j(a,b,W))):(W=$CLJS.k1(a,t),W=$CLJS.n_.v(a,b,W,d))}else W=null;return $CLJS.n(W)?W:$CLJS.n(v)?v:$CLJS.l1(k)}():null;c=$CLJS.n(X)?[$CLJS.p.h(X)," → ",$CLJS.p.h(c)].join(""):c;return $CLJS.n(function(){var W=$CLJS.kk.g(d,$CLJS.o_);return W?C:W}())?c:$CLJS.n($CLJS.n(u)?$CLJS.kk.g(c,f(S)):u)?f(c):$CLJS.n($CLJS.n(l)?$CLJS.kk.g(c,e(S)):l)?e(c):c});
$CLJS.O_.m(null,$CLJS.kF,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.K.g(f,$CLJS.wQ);var k=$CLJS.K.g(f,$CLJS.wO),l=$CLJS.K.g(f,$CLJS.qF);f=$CLJS.K.g(f,$CLJS.HM);$CLJS.I(c,2,null);c=t7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.aZ,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.ei,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.QV,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.aK,f):e;return $CLJS.n(e)?$CLJS.n_.v(a,b,e,d):$CLJS.JD("[Unknown Field]")});
$CLJS.P_.m(null,$CLJS.LJ,function(a,b,c){a=$CLJS.O(c);return $CLJS.K.g(a,$CLJS.T)});$CLJS.P_.m(null,$CLJS.kF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=t7(a,b,c);return $CLJS.n(c)?$CLJS.Q_.j(a,b,c):"unknown_field"});
$CLJS.W_.m(null,$CLJS.LJ,function(a,b,c){return $CLJS.Dk.l($CLJS.H([function(){var d=$CLJS.lH($CLJS.W_,$CLJS.Oh);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.n_.j(a,b,c),$CLJS.p_,$CLJS.n_.v(a,b,c,$CLJS.o_)],null),$CLJS.E.g($CLJS.NJ.h(c),$CLJS.QY)?function(){var d=$CLJS.$Y.h(c);return $CLJS.n(d)?(d=$CLJS.h_(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.Pj,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.lD,$CLJS.T.h(d)],null)],null):null):null}():null]))});
$CLJS.w0.m(null,$CLJS.kF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.qF.h(b)});$CLJS.w0.m(null,$CLJS.LJ,function(a){return $CLJS.LV.h(a)});
$CLJS.v0.m(null,$CLJS.kF,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Dd($CLJS.pG,b),e=$CLJS.qB($CLJS.PV,$CLJS.qi,$CLJS.tA)(c);c=$CLJS.R.l(c,$CLJS.qF,b,$CLJS.H([$CLJS.qi,d?$CLJS.bj:e,$CLJS.PV,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,c,a],null)}b=$CLJS.PV.h(c);c=$CLJS.n(b)?$CLJS.ek.g($CLJS.R.j(c,$CLJS.qi,b),$CLJS.PV):c;c=$CLJS.ek.g(c,$CLJS.qF);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,c,a],null)});
$CLJS.v0.m(null,$CLJS.LJ,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.LV,b,$CLJS.H([$CLJS.PV,$CLJS.qB($CLJS.PV,$CLJS.qi,$CLJS.tA)(a)])):$CLJS.ek.l(a,$CLJS.LV,$CLJS.H([$CLJS.PV]))});$CLJS.y0.m(null,$CLJS.kF,function(a,b,c){return $CLJS.z0.j(a,b,t7(a,b,c))});
$CLJS.y0.m(null,$CLJS.LJ,function(a,b,c){if($CLJS.kk.g($CLJS.NJ.h(c),$CLJS.HY)){a=$CLJS.zz($CLJS.qi,$CLJS.tA)(c);b=null==c?null:$CLJS.VM.h(c);if(null==b)var d=null;else try{var e=$CLJS.Lk.h($CLJS.Vi.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.K.g(f,uta),l=$CLJS.K.g(f,vta),m=$CLJS.kpa.l($CLJS.H([$CLJS.t0.h(k),$CLJS.t0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Eq.g?$CLJS.Eq.g(1,m):$CLJS.Eq.call(null,1,m))?$CLJS.Ei:$CLJS.n($CLJS.Eq.g?$CLJS.Eq.g(31,m):$CLJS.Eq.call(null,31,m))?$CLJS.Qj:$CLJS.n($CLJS.Eq.g?
$CLJS.Eq.g(365,m):$CLJS.Eq.call(null,365,m))?$CLJS.Xi:$CLJS.cj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.Dz(a,$CLJS.Lk)?$CLJS.vpa:$CLJS.Dz(a,$CLJS.Jk)?$CLJS.upa:$CLJS.Dz(a,$CLJS.Ok)?$CLJS.tpa:$CLJS.xf;d=$CLJS.n(d)?w7(e,$CLJS.Oh,d):e;return $CLJS.n($CLJS.LV.h(c))?w7(d,$CLJS.v_,$CLJS.LV.h(c)):d}return $CLJS.xf});
$CLJS.i5.m(null,$CLJS.kF,function(a){var b=null==a?null:$CLJS.FD(a);b=null==b?null:$CLJS.wQ.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Vs,$CLJS.n5,$CLJS.H([$CLJS.m5,function(c,d){return t7(c,d,a)}]))});$CLJS.i5.m(null,$CLJS.LJ,function(a){var b=null==a?null:$CLJS.QV.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Vs,$CLJS.n5,$CLJS.H([$CLJS.m5,$CLJS.Pe(a)]))});$CLJS.o5.m(null,$CLJS.kF,function(a,b){return $CLJS.GD(a,$CLJS.ED,$CLJS.H([$CLJS.wQ,b]))});
$CLJS.o5.m(null,$CLJS.LJ,function(a,b){return $CLJS.ED(a,$CLJS.QV,b)});$CLJS.p5.m(null,$CLJS.kF,function(a,b,c){return $CLJS.q5.j(a,b,t7(a,b,c))});
$CLJS.p5.m(null,$CLJS.LJ,function(a,b,c){b=$CLJS.O(c);c=$CLJS.K.g(b,$CLJS.qi);var d=$CLJS.K.g(b,$CLJS.VM),e=$CLJS.K.g(b,$CLJS.wj);if($CLJS.kk.g($CLJS.NJ.h(b),$CLJS.HY)){var f=function(){var m=null==a?null:$CLJS.a1(a);m=null==m?null:$CLJS.dz.h(m);return null==m?null:$CLJS.Dd(m,$CLJS.wQ)}(),k=$CLJS.Bz(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,$CLJS.sj],null)),l=$CLJS.j5(b);return function u(t){return new $CLJS.ie(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.wd(v)){var x=$CLJS.ic(v),A=$CLJS.D(x),
C=$CLJS.le(A);return function(){for(var J=0;;)if(J<A){var S=$CLJS.id(x,J),X=C,W=S;S=q7(S,l)?$CLJS.R.j(W,$CLJS.v_,!0):W;X.add(S);J+=1}else return!0}()?$CLJS.oe($CLJS.qe(C),u($CLJS.jc(v))):$CLJS.oe($CLJS.qe(C),null)}var G=$CLJS.z(v);return $CLJS.ae(function(){var J=G;return q7(G,l)?$CLJS.R.j(J,$CLJS.v_,!0):J}(),u($CLJS.Hc(v)))}return null}},null,null)}($CLJS.Ra(function(){if($CLJS.n(f)){var t=$CLJS.ui.h(k);return $CLJS.n(t)?$CLJS.Gj.h(k):t}return f}())?null:$CLJS.Dz(e,$CLJS.dj)?$CLJS.nk.g(p7,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.k5(),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.JD("Bin every 0.1 degrees"),$CLJS.kD,new $CLJS.h(null,2,[$CLJS.CD,$CLJS.BD,$CLJS.BD,.1],null)],null),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.JD("Bin every 1 degree"),$CLJS.kD,new $CLJS.h(null,2,[$CLJS.CD,$CLJS.BD,$CLJS.BD,1],null)],null),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.JD("Bin every 10 degrees"),$CLJS.kD,new $CLJS.h(null,2,[$CLJS.CD,$CLJS.BD,$CLJS.BD,10],null)],null),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.JD("Bin every 20 degrees"),$CLJS.kD,new $CLJS.h(null,
2,[$CLJS.CD,$CLJS.BD,$CLJS.BD,20],null)],null)],null)):$CLJS.Dz(c,$CLJS.sj)&&!$CLJS.Dz(e,$CLJS.ai)?$CLJS.nk.g(p7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.k5(),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.JD("10 bins"),$CLJS.kD,new $CLJS.h(null,2,[$CLJS.CD,$CLJS.jD,$CLJS.jD,10],null)],null),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.JD("50 bins"),$CLJS.kD,new $CLJS.h(null,2,[$CLJS.CD,$CLJS.jD,$CLJS.jD,50],null)],null),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.JD("100 bins"),$CLJS.kD,new $CLJS.h(null,2,[$CLJS.CD,$CLJS.jD,$CLJS.jD,
100],null)],null)],null)):null)}return $CLJS.xf});$CLJS.qV.m(null,$CLJS.kF,function(a){return a});
$CLJS.qV.m(null,$CLJS.LJ,function(a){a=$CLJS.O(a);var b=$CLJS.K.g(a,$CLJS.NJ);switch(b instanceof $CLJS.M?b.S:null){case "source/aggregations":return b=new $CLJS.h(null,3,[$CLJS.yD,$CLJS.p.h($CLJS.ID()),$CLJS.qi,$CLJS.zz($CLJS.qi,$CLJS.tA)(a),$CLJS.CK,$CLJS.T.h(a)],null),a=$CLJS.F0.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$E,b,a],null);case "source/expressions":return o7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.pD.h(a))?o7(a):x7(a);default:return x7(a)}});
$CLJS.wta=function(){function a(e,f,k){k=$CLJS.Be($CLJS.nk.g($CLJS.sV,k));var l=rta(e,f),m=$CLJS.Wf.j($CLJS.Sg,$CLJS.xl(function(t){return $CLJS.X1.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.xf);m=$CLJS.mk.g(m,l);k=$CLJS.n(k)?$CLJS.Wf.j(k,$CLJS.cf.h($CLJS.sV),m):null;return $CLJS.SV.l(e,f,$CLJS.ED,$CLJS.H([$CLJS.SD,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.D7=function(){function a(d,e){return $CLJS.SD.h($CLJS.HV(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.xta=function(){function a(d,e){var f=$CLJS.$_.v(d,e,$CLJS.HV(d,e),new $CLJS.h(null,3,[$CLJS.H_,!1,$CLJS.N_,!1,$CLJS.D_,!1],null)),k=$CLJS.D7.g(d,e);return $CLJS.pd(k)?$CLJS.nk.g(function(l){return $CLJS.R.j(l,$CLJS.OY,!0)},f):$CLJS.k3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Y(z7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dr,$CLJS.gj,$CLJS.CY,$CLJS.cz],null));
$CLJS.Y(tta,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.jK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.y7,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.jK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,z7],null)],null)],null));