var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var EK,GK,IK,LK,OK;$CLJS.BK=function(a){return $CLJS.Hh($CLJS.q($CLJS.gB),a,$CLJS.oF)};$CLJS.CK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);$CLJS.DK=new $CLJS.M(null,"column-ref","column-ref",2018188376);EK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.FK=new $CLJS.M(null,"operators","operators",-2064102509);
GK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.HK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);IK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.JK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.KK=new $CLJS.M(null,"dimensions","dimensions",-254818097);
LK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.MK=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.NK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);OK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.PK=new $CLJS.M(null,"null","null",-180137709);
$CLJS.QK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.Y(GK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.wD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.bG],null)],null)],null));$CLJS.Y(OK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,GK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.AD],null)],null)],null));$CLJS.Y(IK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.kF],null),OK,$CLJS.qD],null));
$CLJS.Y(LK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.kF],null),GK,$CLJS.jK],null));
$CLJS.JF.g($CLJS.kF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.kF],null),GK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.jK,$CLJS.qD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,2,[$CLJS.zi,function(a){return $CLJS.sd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.XA(a)):null},$CLJS.ts,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.lA,LK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,IK],null)],null)],null));$CLJS.qE($CLJS.kF,$CLJS.oF);$CLJS.LD.m(null,$CLJS.kF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.qi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.ZD});$CLJS.xE($CLJS.qA,$CLJS.H([$CLJS.qD]));$CLJS.LD.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.qi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.ZD});
$CLJS.qE($CLJS.qA,$CLJS.oF);$CLJS.Y(EK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.wD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.qD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.qD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.qD],null)],null)],null));
$CLJS.JF.g($CLJS.$E,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.$E],null),EK,$CLJS.zj],null));$CLJS.LD.m(null,$CLJS.$E,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.qi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.ZD});$CLJS.qE($CLJS.$E,$CLJS.oF);$CLJS.xE($CLJS.IE,$CLJS.H([$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OJ],null)],null)]));$CLJS.qE($CLJS.IE,$CLJS.oF);
$CLJS.xE($CLJS.fF,$CLJS.H([$CLJS.Zr,$CLJS.ZD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.MJ],null)],null)]));$CLJS.qE($CLJS.fF,$CLJS.oF);
$CLJS.Y($CLJS.oF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,$CLJS.ND,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.Es,function(){return["Valid reference, must be one of these clauses: ",$CLJS.hs(", ",$CLJS.Cz($CLJS.Ed,$CLJS.Hk.g($CLJS.q($CLJS.gB),$CLJS.oF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.BK(a)}],null)],null));