var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var Wga,Xga,Yga,Zga,$ga,aha,bha,OF,cha,dha,eha,fha,gha,hha,iha,jha,QF,kha;$CLJS.KF=new $CLJS.M(null,"supported-field","supported-field",-2061545519);Wga=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.LF=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Xga=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Yga=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.MF=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);Zga=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.NF=new $CLJS.M(null,"display-info","display-info",-816930907);$ga=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);aha=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
bha=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);OF=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);cha=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);dha=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);eha=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);fha=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
gha=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);hha=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);iha=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.PF=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);jha=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);QF=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.RF=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);kha=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.zE($CLJS.Rw,$CLJS.H([$CLJS.Zr,$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.YD],null)],null)],null)],null)]));$CLJS.zE($CLJS.lF,$CLJS.H([$CLJS.Zr,$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.YD],null)],null)],null)],null)]));
$CLJS.xE($CLJS.eF,$CLJS.H([$CLJS.Zr,$CLJS.MC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null)],null)]));$CLJS.xE($CLJS.PE,$CLJS.H([$CLJS.Zr,$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.YD],null)],null)]));$CLJS.xE($CLJS.tF,$CLJS.H([$CLJS.Zr,$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.XD],null)],null)]));
$CLJS.xE($CLJS.Gj,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.dE],null)],null)]));$CLJS.qE($CLJS.Gj,$CLJS.cE);$CLJS.xE($CLJS.xF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null)],null)]));$CLJS.qE($CLJS.xF,$CLJS.cE);$CLJS.xE($CLJS.ui,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.dE],null)],null)]));$CLJS.qE($CLJS.ui,$CLJS.cE);
$CLJS.Y(OF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.nr,new $CLJS.h(null,1,[$CLJS.ts,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.xE($CLJS.WE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,OF],null)]));$CLJS.qE($CLJS.WE,$CLJS.cE);
$CLJS.xE($CLJS.rF,$CLJS.H([$CLJS.Zr,$CLJS.MC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.XD],null)],null)]));$CLJS.xE($CLJS.yF,$CLJS.H([$CLJS.Zr,$CLJS.MC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null)],null)]));$CLJS.xE($CLJS.wF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null)],null)]));
$CLJS.xE($CLJS.FF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null)],null)]));$CLJS.qE($CLJS.wF,$CLJS.cE);$CLJS.qE($CLJS.FF,$CLJS.cE);$CLJS.xE($CLJS.DF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.XD],null)],null)]));$CLJS.qE($CLJS.DF,$CLJS.cE);
$CLJS.xE($CLJS.QE,$CLJS.H([$CLJS.Zr,$CLJS.MC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null)],null)]));$CLJS.Y(QF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.gr,jha,hha,aha,Xga,Wga,$ga,bha,cha,dha,gha,eha,kha,iha,Yga,fha,$CLJS.dl],null));$CLJS.Y($CLJS.LF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.ui,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,QF],null)],null));
$CLJS.SF=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.dF,$CLJS.Rw,$CLJS.MF,!1,$CLJS.PF,$CLJS.EE,$CLJS.NF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Count of rows"),$CLJS.VE,$CLJS.JD("Count"),$CLJS.Hs,$CLJS.JD("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.dF,$CLJS.wF,$CLJS.KF,$CLJS.Dj,$CLJS.MF,!0,$CLJS.PF,$CLJS.EE,$CLJS.NF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Sum of ..."),$CLJS.VE,$CLJS.JD("Sum"),$CLJS.Hs,$CLJS.JD("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.dF,$CLJS.eF,$CLJS.KF,$CLJS.Dj,$CLJS.MF,!0,$CLJS.PF,$CLJS.EE,$CLJS.NF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Average of ..."),$CLJS.VE,$CLJS.JD("Average"),$CLJS.Hs,$CLJS.JD("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.dF,$CLJS.xF,$CLJS.KF,$CLJS.Dj,$CLJS.MF,!0,$CLJS.PF,$CLJS.gF,$CLJS.NF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Median of ..."),$CLJS.VE,$CLJS.JD("Median"),$CLJS.Hs,$CLJS.JD("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.dF,$CLJS.PE,$CLJS.KF,$CLJS.Zh,$CLJS.MF,!0,$CLJS.PF,$CLJS.EE,$CLJS.NF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Number of distinct values of ..."),$CLJS.VE,$CLJS.JD("Distinct values"),$CLJS.Hs,$CLJS.JD("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.dF,$CLJS.FF,$CLJS.KF,$CLJS.Dj,$CLJS.MF,!0,$CLJS.PF,$CLJS.EE,$CLJS.NF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Cumulative sum of ..."),
$CLJS.VE,$CLJS.JD("Sum"),$CLJS.Hs,$CLJS.JD("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.dF,$CLJS.lF,$CLJS.MF,!1,$CLJS.PF,$CLJS.EE,$CLJS.NF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Cumulative count of rows"),$CLJS.VE,$CLJS.JD("Count"),$CLJS.Hs,$CLJS.JD("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.dF,$CLJS.yF,$CLJS.KF,$CLJS.Dj,$CLJS.MF,
!0,$CLJS.PF,$CLJS.uF,$CLJS.NF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Standard deviation of ..."),$CLJS.VE,$CLJS.JD("SD"),$CLJS.Hs,$CLJS.JD("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.dF,$CLJS.ui,$CLJS.KF,$CLJS.Ji,$CLJS.MF,!0,$CLJS.PF,$CLJS.EE,$CLJS.NF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Minimum of ..."),$CLJS.VE,$CLJS.JD("Min"),$CLJS.Hs,$CLJS.JD("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.dF,$CLJS.Gj,$CLJS.KF,$CLJS.Ji,$CLJS.MF,!0,$CLJS.PF,$CLJS.EE,$CLJS.NF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Maximum of ..."),$CLJS.VE,$CLJS.JD("Max"),$CLJS.Hs,$CLJS.JD("Maximum value of a column")],null)}],null)],null);
$CLJS.Y(Zga,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.RF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dF,$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dr],null),$CLJS.cf.h($CLJS.dF),$CLJS.SF)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.Ii],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MF,$CLJS.ur],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.PF,$CLJS.Ii],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NF,$CLJS.jd],null)],null));