var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Wqa,Xqa,Yqa,Zqa,n3,o3,p3,q3,$qa,ara,bra,cra,dra,era,fra,gra,hra,A3,B3,ira,jra,kra,z3,y3,lra,C3;$CLJS.h3=function(a,b){$CLJS.cb(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
Wqa=function(a){return function(b){var c=$CLJS.Ve(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.gl(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};Xqa=function(a,b){return function f(d,e){return new $CLJS.ie(null,function(){var k;a:{var l=d;for(k=e;;){var m=l;l=$CLJS.I(m,0,null);if(m=$CLJS.y(m)){var t=a.h?a.h(l):a.call(null,l);if($CLJS.Dd(k,t))l=$CLJS.Hc(m);else{k=$CLJS.ae(l,f($CLJS.Hc(m),$CLJS.Yd.g(k,t)));break a}}else{k=null;break a}}}return k},null,null)}(b,$CLJS.Sg)};
Yqa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.w(b.slice(3),0,null):null;return $CLJS.Ra($CLJS.y($CLJS.Bz(c,d)))?c:$CLJS.Ne.M($CLJS.yW,c,d,e,b)};$CLJS.i3=function(a){return(0,$CLJS.ma)($CLJS.Gz(a,$CLJS.Wma,""))};$CLJS.j3=function(a,b){return $CLJS.v0.g(a,b instanceof $CLJS.M?b:$CLJS.ei.h(b))};
$CLJS.k3=function(a,b,c,d){if($CLJS.y(c)){d=$CLJS.nk.g($CLJS.sV,d);var e=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h(function(f){return $CLJS.X1.v(a,b,f,c)}),d);return $CLJS.nk.g(function(f){return $CLJS.R.j(f,$CLJS.OY,$CLJS.Dd(e,f))},c)}return null};$CLJS.l3=function(a){return $CLJS.E.g($CLJS.XA(a),$CLJS.oK)};Zqa=function(a,b){return $CLJS.n($CLJS.SD.h(a))?$CLJS.ok.j(a,$CLJS.SD,function(c){return $CLJS.sd(c)?$CLJS.nk.g(function(d){return $CLJS.m3.g?$CLJS.m3.g(d,b):$CLJS.m3.call(null,d,b)},c):c}):a};
n3=function(a){if($CLJS.n(a)){var b=$CLJS.ff;a:try{if($CLJS.vd(a)&&4===$CLJS.D(a))try{if($CLJS.F(a,0)instanceof $CLJS.M)try{var c=$CLJS.F(a,2);if($CLJS.vd(c)&&3===$CLJS.D(c))try{var d=$CLJS.F(c,0);if($CLJS.ce(d,$CLJS.kF))try{var e=$CLJS.F(a,3);if($CLJS.vd(e)&&3===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.ce(f,$CLJS.kF)){$CLJS.F(e,1);$CLJS.F(e,2);$CLJS.F(c,1);$CLJS.F(c,2);$CLJS.F(a,0);$CLJS.F(a,1);var k=new $CLJS.P(null,1,5,$CLJS.Q,[!0],null)}else throw $CLJS.Z;}catch(m){if(m instanceof Error){var l=
m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Z){k=new $CLJS.P(null,1,5,$CLJS.Q,[!1],null);break a}throw l;}throw m;}a=$CLJS.z($CLJS.y(b($CLJS.Sa,k)))}else a=null;return a};o3=function(a){if($CLJS.n(n3(a))){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);$CLJS.I(a,3,null);return b}return null};p3=function(a){return $CLJS.n(n3(a))?($CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),$CLJS.I(a,3,null)):null};
q3=function(a,b){var c=$CLJS.m3;if($CLJS.Ra(n3(a)))return a;var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null),f=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[d,e,f,$CLJS.Ne.j(c,a,b)],null)};
$qa=function(a,b,c){return $CLJS.pd($CLJS.wK.h(a))?a:$CLJS.n(b)?Yqa(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.wK],null),function(d){return function l(f,k){try{if($CLJS.vd(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.ce(m,$CLJS.kF))try{var t=$CLJS.F(k,1);if(null!=t?t.C&256||$CLJS.yc===t.mf||(t.C?0:$CLJS.Ya($CLJS.ub,t)):$CLJS.Ya($CLJS.ub,t))try{var u=$CLJS.K.j(t,$CLJS.wO,$CLJS.FU);if($CLJS.E.g(u,b))return $CLJS.F(k,2),$CLJS.m3.g?$CLJS.m3.g(k,c):$CLJS.m3.call(null,k,c);throw $CLJS.Z;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)return $CLJS.$V(l,f,k);throw v;}throw x;}}($CLJS.xf,d)}):$CLJS.ok.j(a,$CLJS.wK,function(d){return $CLJS.nk.g(function(e){return q3(e,$CLJS.H([c]))},d)})};
$CLJS.m3=function(a,b){var c=$CLJS.XA(a);switch(c instanceof $CLJS.M?c.S:null){case "field":return $CLJS.GD(a,$CLJS.ED,$CLJS.H([$CLJS.wO,b]));case "metadata/column":return $CLJS.ED(a,$CLJS.gZ,b);case "mbql/join":return c=$CLJS.l1(a),$qa(Zqa($CLJS.ED(a,$CLJS.WD,b),b),c,b);default:return b=$CLJS.Mz($CLJS.Cx),$CLJS.n($CLJS.Lz("metabase.lib.join",b))&&$CLJS.Kz("metabase.lib.join",b,$CLJS.xv.l($CLJS.H(["with-join-value should not be called with",$CLJS.xh.l($CLJS.H([a]))])),null),a}};
$CLJS.r3=function(a,b,c){var d=$CLJS.R.l,e=$CLJS.zz($CLJS.yY,$CLJS.T)(c);a=$CLJS.m1($CLJS.WD.h(a),$CLJS.zz($CLJS.yY,$CLJS.T)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.R,c,$CLJS.yY,e,$CLJS.H([$CLJS.NY,b]))};ara=function(a,b){return $CLJS.Ra(b)?a:$CLJS.nk.g(function(c){var d=p3(c);d=$CLJS.n(d)?$CLJS.n($CLJS.l1(d))?null:q3(c,$CLJS.H([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.s3=function(a,b){b=ara($CLJS.nk.g($CLJS.tV,b),$CLJS.l1(a));return $CLJS.ED(a,$CLJS.wK,$CLJS.Be(b))};
$CLJS.t3=function(a,b){b=b instanceof $CLJS.M?b:$CLJS.E.g(b,$CLJS.xf)?$CLJS.cz:$CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.lk.g($CLJS.cf.h($CLJS.sV),function(){var c=$CLJS.l1(a);return $CLJS.n(c)?$CLJS.cf.h(function(d){return $CLJS.m3(d,c)}):$CLJS.Pd}()),b));return $CLJS.ED(a,$CLJS.SD,b)};
bra=function(a,b){b=$CLJS.Dl(function(d){return $CLJS.X1.g(d,a)},b);var c=$CLJS.sG($CLJS.zz($CLJS.MZ,$CLJS.NZ),b);if($CLJS.n(c))return c;b=$CLJS.z(b);if($CLJS.n(b))return b;b=$CLJS.sG($CLJS.MZ,a);if($CLJS.n(b))return b;b=$CLJS.sG($CLJS.NZ,a);return $CLJS.n(b)?b:$CLJS.z(a)};cra=function(a){return"string"===typeof a?(0,$CLJS.ma)($CLJS.Gz(a,/ id$/i,"")):null};
dra=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.uh(function(){return $CLJS.s2(b)});var d=new $CLJS.uh(function(){return $CLJS.s2(a)});return $CLJS.E.g(a,b)||$CLJS.E.g(a,$CLJS.q(c))||$CLJS.E.g($CLJS.q(d),b)||$CLJS.E.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
era=function(a,b,c){var d=$CLJS.n_.g($CLJS.E.g($CLJS.Vs.h(b),$CLJS.jV)?b:a,b),e=$CLJS.n(c)?cra($CLJS.n_.g(a,c)):null,f=dra(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Ra($CLJS.dh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
fra=function(a,b,c,d,e){return function m(k,l){try{if($CLJS.K1(l,$CLJS.kF)&&$CLJS.Ad($CLJS.X1.v(a,b,l,e)))return $CLJS.m3(l,d);throw $CLJS.Z;}catch(u){if(u instanceof Error){var t=u;if(t===$CLJS.Z)return $CLJS.$V(m,k,l);throw t;}throw u;}}($CLJS.xf,c)};
gra=function(a,b,c,d,e,f){c=fra(a,b,c,d,f);return function t(l,m){try{if($CLJS.vd(m)&&4===$CLJS.D(m))try{var u=$CLJS.F(m,2);if($CLJS.K1(u,$CLJS.kF))try{var v=$CLJS.F(m,3);if($CLJS.K1(v,$CLJS.kF)){var x=$CLJS.F(m,3),A=$CLJS.F(m,2),C=$CLJS.F(m,0),G=$CLJS.F(m,1),J=$CLJS.l1(A),S=$CLJS.l1(x);if($CLJS.Ra($CLJS.n(J)?J:S))return $CLJS.n($CLJS.X1.v(a,b,x,e))?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,$CLJS.m3(A,d),x],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,$CLJS.m3(x,d)],null);if($CLJS.E.g(J,d)&&$CLJS.E.g(S,d)){var X=
$CLJS.GD(A,$CLJS.ek,$CLJS.H([$CLJS.wO])),W=$CLJS.GD(x,$CLJS.ek,$CLJS.H([$CLJS.wO])),da=null==$CLJS.X1.v(a,b,X,e);var ra=da?$CLJS.X1.v(a,b,W,e):da;return $CLJS.n(ra)?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,W],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,X,x],null)}return m}throw $CLJS.Z;}catch(sb){if(sb instanceof Error){var Ma=sb;if(Ma===$CLJS.Z)throw $CLJS.Z;throw Ma;}throw sb;}else throw $CLJS.Z;}catch(sb){if(sb instanceof Error){Ma=sb;if(Ma===$CLJS.Z)throw $CLJS.Z;throw Ma;}throw sb;}else throw $CLJS.Z;
}catch(sb){if(sb instanceof Error){Ma=sb;if(Ma===$CLJS.Z)return $CLJS.$V(t,l,m);throw Ma;}throw sb;}}($CLJS.xf,c)};hra=function(a,b){var c=$CLJS.KV();$CLJS.h3(c,b);return c(a)};
$CLJS.u3=function(a,b,c){if($CLJS.Dd(c,$CLJS.WD))return c;var d=$CLJS.HV(a,b),e=$CLJS.$_.j(a,b,d),f=$CLJS.y($CLJS.ff($CLJS.Sa,function v(t,u){try{if($CLJS.vd(u)&&1<=$CLJS.D(u))try{var x=$CLJS.sk.j(u,0,1);if($CLJS.vd(x)&&1===$CLJS.D(x))try{var A=$CLJS.F(x,0);if($CLJS.ce(A,$CLJS.kF))return new $CLJS.P(null,1,5,$CLJS.Q,[u],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var C=G;if(C===$CLJS.Z)throw $CLJS.Z;throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)throw $CLJS.Z;
throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)return $CLJS.CU(v,t,u);throw C;}throw G;}}($CLJS.xf,$CLJS.wK.h(c))));f=bra(e,f);var k=hra(era(a,c,f),$CLJS.Dl($CLJS.WD,$CLJS.GM.h(d))),l=$CLJS.Y_.h($CLJS.f1.g(a,$CLJS.tK.h(c)));return $CLJS.m3($CLJS.ok.j(c,$CLJS.wK,function(t){return $CLJS.nk.g(function(u){return gra(a,b,u,k,e,l)},t)}),k)};$CLJS.v3=function(a){return $CLJS.wK.h(a)};
$CLJS.w3=function(a){return $CLJS.Dk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.xK,$CLJS.CD,a],null),$CLJS.E.g(a,$CLJS.aE)?new $CLJS.h(null,1,[$CLJS.Oh,!0],null):null]))};$CLJS.x3=function(a,b){b=$CLJS.z($CLJS.tK.h(b));return $CLJS.n($CLJS.QU.h(b))?$CLJS.h_(a,$CLJS.QU.h(b)):$CLJS.n($CLJS.hO.h(b))?$CLJS.f_(a,$CLJS.hO.h(b)):null};
A3=function(a){a=$CLJS.rG(function(d){return $CLJS.NZ(d)?y3:$CLJS.MZ(d)?z3:$CLJS.Rs},a);var b=$CLJS.O(a);a=$CLJS.K.g(b,y3);var c=$CLJS.K.g(b,z3);b=$CLJS.K.g(b,$CLJS.Rs);return $CLJS.bf.l(a,c,$CLJS.H([b]))};B3=function(a,b,c,d){return $CLJS.Ra(c)?d:$CLJS.nk.g(function(e){return $CLJS.n($CLJS.OY.h(e))?$CLJS.j3(e,$CLJS.q2(c)):e},$CLJS.k3(a,b,d,new $CLJS.P(null,1,5,$CLJS.Q,[c],null)))};
ira=function(a,b,c,d){var e=new $CLJS.uh(function(){return $CLJS.$_.v(a,b,d,new $CLJS.h(null,2,[$CLJS.D_,!1,$CLJS.L_,!1],null))});return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.xl(function(f){var k=$CLJS.O(f),l=$CLJS.K.g(k,$CLJS.ZJ);return $CLJS.n(function(){var m=$CLJS.MZ(k);return m?l:m}())?(f=$CLJS.sG(function(m){return $CLJS.E.g(l,$CLJS.Ki.h(m))},$CLJS.q(e)),$CLJS.n(f)?$CLJS.R.j(k,C3,f):null):null}),$CLJS.$_.v(a,b,c,new $CLJS.h(null,2,[$CLJS.D_,!1,$CLJS.L_,!1],null))))};jra={};
kra=new $CLJS.M("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);z3=new $CLJS.M(null,"fk","fk",398442651);y3=new $CLJS.M(null,"pk","pk",-771936732);lra=new $CLJS.r("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);C3=new $CLJS.M("metabase.lib.join","target","metabase.lib.join/target",203858838);$CLJS.O_.m(null,$CLJS.oK,function(a,b,c){b=$CLJS.O(c);b=$CLJS.K.g(b,$CLJS.tK);b=$CLJS.I(b,0,null);b=$CLJS.O(b);c=$CLJS.K.g(b,$CLJS.hO);b=$CLJS.K.g(b,$CLJS.QU);c=$CLJS.n(c)?$CLJS.lD.h($CLJS.f_(a,c)):null;if($CLJS.n(c))return c;$CLJS.n(b)?(c=$CLJS.h_(a,b),a=$CLJS.n(c)?$CLJS.n_.j(a,0,c):$CLJS.q1(b)):a=null;return $CLJS.n(a)?a:$CLJS.JD("Native Query")});$CLJS.W_.m(null,$CLJS.oK,function(a,b,c){a=$CLJS.n_.j(a,b,c);c=$CLJS.WD.h(c);return new $CLJS.h(null,2,[$CLJS.T,$CLJS.n(c)?c:a,$CLJS.lD,a],null)});
$CLJS.V_.m(null,$CLJS.oK,function(){throw $CLJS.Uh("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.O_.m(null,$CLJS.xK,function(a,b,c){a=$CLJS.O(c);a=$CLJS.K.g(a,$CLJS.CD);a=a instanceof $CLJS.M?a.S:null;switch(a){case "left-join":return $CLJS.JD("Left outer join");case "right-join":return $CLJS.JD("Right outer join");case "inner-join":return $CLJS.JD("Inner join");case "full-join":return $CLJS.JD("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.W_.m(null,$CLJS.xK,function(a,b,c){var d=$CLJS.O(c),e=$CLJS.K.g(d,$CLJS.CD);c=$CLJS.K.g(d,$CLJS.Oh);a=new $CLJS.h(null,2,[$CLJS.M_,$CLJS.$z(e),$CLJS.lD,$CLJS.n_.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.Oh,!0):a});
$CLJS.X_.m(null,$CLJS.oK,function(a,b,c,d){var e=$CLJS.O(c),f=$CLJS.K.g(e,$CLJS.WD),k=$CLJS.K.j(e,$CLJS.SD,$CLJS.cz),l=$CLJS.K.g(e,$CLJS.tK);c=$CLJS.O(d);var m=$CLJS.K.g(c,$CLJS.J_);if($CLJS.E.g(k,$CLJS.cz))return null;var t="undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jra&&"undefined"!==typeof $CLJS.mra&&"undefined"!==typeof $CLJS.D3?new $CLJS.Cc(function(){return $CLJS.D3},$CLJS.ld(lra,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),null):null,u=function(){var v=$CLJS.R.j(a,$CLJS.tK,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.E.g(k,$CLJS.wx)?$CLJS.Y_.v(u,-1,$CLJS.nd(l),c):function(){return function A(x){return new $CLJS.ie(null,function(){for(;;){var C=$CLJS.y(x);if(C){if($CLJS.wd(C)){var G=$CLJS.ic(C),J=$CLJS.D(G),S=$CLJS.le(J);a:for(var X=0;;)if(X<J){var W=$CLJS.id(G,X);W=$CLJS.GD(W,$CLJS.ek,$CLJS.H([$CLJS.wO]));W=$CLJS.l_.j(u,-1,W);S.add(W);X+=1}else{G=!0;break a}return G?$CLJS.oe($CLJS.qe(S),A($CLJS.jc(C))):$CLJS.oe($CLJS.qe(S),null)}S=$CLJS.z(C);S=$CLJS.GD(S,$CLJS.ek,$CLJS.H([$CLJS.wO]));
return $CLJS.ae($CLJS.l_.j(u,-1,S),A($CLJS.Hc(C)))}return null}},null,null)}(k)}();return $CLJS.nk.g(function(v){v=$CLJS.R.j(v,$CLJS.aZ,f);v=$CLJS.m3($CLJS.R.l(v,$CLJS.lD,$CLJS.n_.j(a,b,v),$CLJS.H([$CLJS.NJ,$CLJS.KY])),f);return $CLJS.r3(e,m,v)},c)});$CLJS.Z_.m(null,$CLJS.oK,function(a,b,c,d){return $CLJS.Y_.v(a,b,$CLJS.R.j(c,$CLJS.SD,$CLJS.wx),d)});
var E3,nra=$CLJS.Se($CLJS.N),ora=$CLJS.Se($CLJS.N),pra=$CLJS.Se($CLJS.N),qra=$CLJS.Se($CLJS.N),rra=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Si,$CLJS.gB],null),$CLJS.Si,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));E3=new $CLJS.Ph($CLJS.qh.g("metabase.lib.join","join-clause-method"),$CLJS.XA,rra,nra,ora,pra,qra);E3.m(null,$CLJS.oK,function(a){return a});E3.m(null,$CLJS.jV,function(a){return $CLJS.rV(new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.oK,$CLJS.tK,$CLJS.tK.h($CLJS.FV(a))],null))});
E3.m(null,$CLJS.XU,function(a){return $CLJS.rV(new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.oK,$CLJS.tK,new $CLJS.P(null,1,5,$CLJS.Q,[a],null)],null))});E3.m(null,$CLJS.kK,function(a){return $CLJS.rV(new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.oK,$CLJS.tK,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.QU,$CLJS.Ki.h(a),$CLJS.Vs,$CLJS.XU],null)],null)],null))});
E3.m(null,$CLJS.TJ,function(a){var b=$CLJS.O(a),c=$CLJS.K.g(b,$CLJS.gZ);a=$CLJS.K.g(b,kra);b=E3.h(new $CLJS.h(null,3,[$CLJS.Vs,$CLJS.XU,$CLJS.uA,new $CLJS.h(null,1,[$CLJS.yD,$CLJS.p.h($CLJS.ID())],null),$CLJS.hO,$CLJS.Ki.h(b)],null));c=$CLJS.n(c)?$CLJS.m3(b,c):b;return $CLJS.n(a)?$CLJS.t3.g?$CLJS.t3.g(c,a):$CLJS.t3.call(null,c,a):c});
$CLJS.F3=function(){function a(d,e){return $CLJS.s3(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.I1(E3.h(d),$CLJS.SD,$CLJS.wx)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.sra=function(){function a(d,e,f){var k=$CLJS.F3.h(f);f=$CLJS.pd($CLJS.v3.h?$CLJS.v3.h(k):$CLJS.v3.call(null,k))?function(){var m=$CLJS.x3.g?$CLJS.x3.g(d,k):$CLJS.x3.call(null,d,k);return $CLJS.G3.j?$CLJS.G3.j(d,e,m):$CLJS.G3.call(null,d,e,m)}():null;f=$CLJS.y(f)?$CLJS.s3(k,f):k;var l=$CLJS.u3(d,e,f);return $CLJS.SV.l(d,e,$CLJS.ok,$CLJS.H([$CLJS.GM,function(m){return $CLJS.Yd.g($CLJS.yf(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.H3=function(){function a(d,e){return $CLJS.Be($CLJS.K.g($CLJS.HV(d,e),$CLJS.GM))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.tra=function(){function a(d){d=$CLJS.a1(d);d=$CLJS.dz.h(d);return $CLJS.Wf.j($CLJS.xf,$CLJS.lk.g($CLJS.ef($CLJS.Qe($CLJS.Dd,d)),$CLJS.cf.h($CLJS.w3)),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.aE,$CLJS.RD,$CLJS.eE,$CLJS.hE],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.ura=function(){function a(d,e,f,k){var l=$CLJS.l3(f)?$CLJS.l1(f):null,m=$CLJS.Wf.j($CLJS.Sg,$CLJS.lk.g($CLJS.cf.h($CLJS.l1),Wqa(function(t){return $CLJS.kk.g(t,l)})),$CLJS.H3.g(d,e));f=$CLJS.n(k)?k:$CLJS.l3(f)?o3($CLJS.z($CLJS.v3(f))):null;return A3(B3(d,e,f,$CLJS.mk.g(function(t){t=$CLJS.l1(t);return $CLJS.n(t)?$CLJS.Dd(m,t):null},$CLJS.$_.v(d,e,$CLJS.HV(d,e),new $CLJS.h(null,1,[$CLJS.D_,!1],null)))))}function b(d,e,f,k){return c.M?c.M(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.M=a;return c}();
$CLJS.vra=function(){function a(d,e,f,k,l){k=$CLJS.l3(f)?$CLJS.x3(d,f):f;var m=$CLJS.l3(f)?$CLJS.l1(f):null;f=$CLJS.n(l)?l:$CLJS.l3(f)?p3($CLJS.z($CLJS.v3(f))):null;f=$CLJS.n(f)?$CLJS.Ra(m)?$CLJS.GD(f,$CLJS.ek,$CLJS.H([$CLJS.wO])):f:null;return A3(B3(d,e,f,$CLJS.cf.g(function(t){t=$CLJS.R.j(t,$CLJS.NJ,$CLJS.KY);return $CLJS.n(m)?$CLJS.m3(t,m):t},$CLJS.$_.v(d,e,k,new $CLJS.h(null,1,[$CLJS.D_,!1],null)))))}function b(d,e,f,k){return c.M?c.M(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.M=a;return c}();$CLJS.wra=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.c2}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.c2};return b}();
$CLJS.G3=function(){function a(d,e,f){function k(u,v){u=$CLJS.ek.g(u,$CLJS.s1);v=$CLJS.ek.g(v,$CLJS.s1);return $CLJS.f3.l($CLJS.$1.h($CLJS.Kq),u,$CLJS.H([v]))}function l(u,v){return $CLJS.Be(Xqa(function(x){return $CLJS.Ki.h(C3.h(x))},ira(d,e,u,v)))}var m=$CLJS.HV(d,e),t=function(){var u=l(m,f);return $CLJS.n(u)?$CLJS.nk.g(function(v){return k(v,C3.h(v))},u):null}();if($CLJS.n(t))return t;t=l(f,m);return $CLJS.n(t)?$CLJS.nk.g(function(u){return k(C3.h(u),u)},t):null}function b(d,e){return c.j?c.j(d,
-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.xra=$CLJS.cf.h(function(a){return $CLJS.R.j(a,$CLJS.NJ,$CLJS.KY)});
$CLJS.yra=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.l3(k)?o3($CLJS.z($CLJS.v3(k))):null;$CLJS.n(l)?(l=$CLJS.q_.j(e,f,l),l=$CLJS.Bz(l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.lD],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.GV(e,f)){l=$CLJS.H3.g(e,f);var m=$CLJS.pd(l);k=m?m:$CLJS.l3(k)?$CLJS.E.g($CLJS.WD.h(k),$CLJS.WD.h($CLJS.z(l))):null;k=$CLJS.n(k)?$CLJS.a_(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.a_(e),k=$CLJS.f_(e,k),e=$CLJS.n_.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.JD("Previous results")}
function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.zra=function(){function a(d,e,f,k){f=$CLJS.tV.h(f);$CLJS.I(f,0,null);$CLJS.I(f,1,null);var l=$CLJS.I(f,2,null),m=$CLJS.I(f,3,null);k=k instanceof $CLJS.M?k:$CLJS.ei.h(k);e=$CLJS.GV(d,e);l=$CLJS.z0.j(d,e,l);d=$CLJS.z0.j(d,e,m);m=null==k||$CLJS.Dd($CLJS.Wg($CLJS.cf.g($CLJS.ei,l)),k);d=null==k||$CLJS.Dd($CLJS.Wg($CLJS.cf.g($CLJS.ei,d)),k);f=m?$CLJS.ok.v(f,2,$CLJS.j3,k):f;return d?$CLJS.ok.v(f,3,$CLJS.j3,k):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.R_.m(null,$CLJS.GM,function(a,b){var c=$CLJS.Be($CLJS.H3.g(a,b));c=null==c?null:$CLJS.cf.g(function(d){return $CLJS.n_.j(a,b,d)},c);return null==c?null:$CLJS.hs(" + ",c)});