var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");
'use strict';$CLJS.k1=function(a,b){var c=$CLJS.IV(b);return $CLJS.n(c)?$CLJS.h_(a,c):$CLJS.f_(a,b)};$CLJS.l1=function(a){var b=$CLJS.XA(a);b=b instanceof $CLJS.M?b.S:null;switch(b){case "dispatch-type/nil":return null;case "field":return $CLJS.wO.h($CLJS.FD(a));case "metadata/column":return $CLJS.gZ.h(a);case "mbql/join":return $CLJS.WD.h(a);default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};
$CLJS.m1=function(a,b){return $CLJS.ha.j?$CLJS.ha.j("%s__%s",a,b):$CLJS.ha.call(null,"%s__%s",a,b)};$CLJS.n1=function(a,b){var c=$CLJS.l1(b);if($CLJS.n(c))a=c;else a:{var d=$CLJS.O(b);c=$CLJS.K.g(d,$CLJS.aK);d=$CLJS.K.g(d,$CLJS.bK);if($CLJS.n($CLJS.n(c)?d:c)&&(d=$CLJS.k1(a,d),$CLJS.n(d))){d=$CLJS.T.h(d);a=$CLJS.T.h($CLJS.b1(a,c));a=$CLJS.ha.j?$CLJS.ha.j("%s__via__%s",d,a):$CLJS.ha.call(null,"%s__via__%s",d,a);break a}a=null}return $CLJS.n(a)?$CLJS.m1(a,$CLJS.T.h(b)):$CLJS.T.h(b)};
$CLJS.o1=function(a,b,c,d){var e=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.bK),c);return $CLJS.Wf.j($CLJS.xf,$CLJS.lk.l($CLJS.ef($CLJS.ZJ),$CLJS.ef($CLJS.Ki),$CLJS.ef($CLJS.lk.g($CLJS.bl,$CLJS.Ki)),$CLJS.H([$CLJS.cf.h(function(f){f=$CLJS.O(f);var k=$CLJS.K.g(f,$CLJS.Ki),l=$CLJS.K.g(f,$CLJS.ZJ);return $CLJS.R.l($CLJS.b1(a,l),$CLJS.E_,k,$CLJS.H([$CLJS.F_,$CLJS.gZ.h(f)]))}),$CLJS.mk.h(function(f){return $CLJS.Dd(e,$CLJS.bK.h(f))}),$CLJS.fD(function(f){f=$CLJS.O(f);var k=$CLJS.K.g(f,$CLJS.bK),l=$CLJS.K.g(f,
$CLJS.E_),m=$CLJS.K.g(f,$CLJS.F_);f=$CLJS.f_(a,k);return function v(u){return new $CLJS.ie(null,function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.wd(x)){var A=$CLJS.ic(x),C=$CLJS.D(A),G=$CLJS.le(C);return function(){for(var X=0;;)if(X<C){var W=$CLJS.id(A,X),da=$CLJS.R.l(W,$CLJS.aK,l,$CLJS.H([$CLJS.B_,m,$CLJS.NJ,$CLJS.JY,$CLJS.yY,$CLJS.T.h(W)]));W=G;var ra=$CLJS.R,Ma=ra.j,sb=da;da=$CLJS.n1(a,da);da=d.h?d.h(da):d.call(null,da);ra=Ma.call(ra,sb,$CLJS.NY,da);W.add(ra);X+=1}else return!0}()?$CLJS.oe($CLJS.qe(G),
v($CLJS.jc(x))):$CLJS.oe($CLJS.qe(G),null)}var J=$CLJS.z(x),S=$CLJS.R.l(J,$CLJS.aK,l,$CLJS.H([$CLJS.B_,m,$CLJS.NJ,$CLJS.JY,$CLJS.yY,$CLJS.T.h(J)]));return $CLJS.ae($CLJS.R.j(S,$CLJS.NY,function(){var X=$CLJS.n1(a,S);return d.h?d.h(X):d.call(null,X)}()),v($CLJS.Hc(x)))}return null}},null,null)}($CLJS.Z_.v(a,b,f,new $CLJS.h(null,2,[$CLJS.J_,d,$CLJS.D_,!1],null)))})])),c)};$CLJS.p1=function(a,b){return $CLJS.g1.g(a,b)};$CLJS.q1=function(a){return $CLJS.gD("Question {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([a]))]))};
$CLJS.r1=function(a,b){var c=$CLJS.SD.h(b);$CLJS.n(c)||(c=$CLJS.TY.h(b),$CLJS.n(c)||(c=$CLJS.SY.h(b),c=null!=c?$CLJS.Y_.h($CLJS.p1(a,$CLJS.$X.h(c))):null));return $CLJS.n(c)?(c=$CLJS.Be($CLJS.td(c)?$CLJS.EV.h(c):$CLJS.sd(c)?c:null),$CLJS.n(c)?$CLJS.nk.g($CLJS.Xs(Spa,a,b),c):null):null};$CLJS.s1=new $CLJS.M("metabase.lib.card","force-broken-id-refs","metabase.lib.card/force-broken-id-refs",-868945458);$CLJS.t1=new $CLJS.M(null,"field-ref","field-ref",-1699303428);$CLJS.O_.m(null,$CLJS.kK,function(a,b,c){return $CLJS.zz($CLJS.lD,$CLJS.T)(c)});$CLJS.V_.m(null,$CLJS.kK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.K.g(a,$CLJS.T);c=$CLJS.K.g(a,$CLJS.lD);return $CLJS.Ra(c)?$CLJS.R.j(a,$CLJS.lD,$CLJS.j1.g($CLJS.xA,b)):a});
$CLJS.Z_.m(null,$CLJS.kK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.K.g(c,$CLJS.SD),f=$CLJS.K.g(c,$CLJS.TY);d=$CLJS.O(d);var k=$CLJS.K.g(d,$CLJS.D_),l=$CLJS.K.g(d,$CLJS.J_);return $CLJS.bf.g($CLJS.Y_.v(a,b,c,d),$CLJS.n(k)?$CLJS.o1(a,b,$CLJS.bf.g(e,f),l):null)});$CLJS.R_.m(null,$CLJS.QU,function(a,b){var c=$CLJS.HV(a,b);c=$CLJS.O(c);c=$CLJS.K.g(c,$CLJS.QU);if($CLJS.n(c)){var d=$CLJS.h_(a,c);a=$CLJS.n(d)?$CLJS.n_.v(a,b,d,$CLJS.o_):null;return $CLJS.n(a)?a:$CLJS.q1(c)}return null});
var Spa=function(){function a(d,e,f){var k=$CLJS.ek.g($CLJS.Ez(f,$CLJS.eA),$CLJS.t1);f=$CLJS.Dk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.tA,$CLJS.fj,$CLJS.Vs,$CLJS.LJ],null),function(){var l=$CLJS.Ki.h(k);if($CLJS.n(l))try{return $CLJS.b1(d,l)}catch(m){return null}else return null}(),k,new $CLJS.h(null,3,[$CLJS.Vs,$CLJS.LJ,$CLJS.NJ,$CLJS.QY,$CLJS.yY,$CLJS.zz($CLJS.yY,$CLJS.T)(k)],null)]));f=$CLJS.n(e)?$CLJS.R.j(f,$CLJS.$Y,$CLJS.Y0(e)):f;e=$CLJS.n($CLJS.n(!0)?$CLJS.Ra(e)||$CLJS.Ra($CLJS.FY.h($CLJS.h_(d,
$CLJS.Y0(e)))):!0)?$CLJS.R.j(f,$CLJS.s1,!0):f;return $CLJS.kk.g($CLJS.wj.h(k),$CLJS.Xh)?$CLJS.R.j(e,$CLJS.ZJ,null):e}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.X_.m(null,$CLJS.kK,function(a,b,c,d){b=$CLJS.O(d);var e=$CLJS.K.g(b,$CLJS.J_);return $CLJS.nk.g(function(f){var k=$CLJS.qB($CLJS.NY,$CLJS.yY,$CLJS.T)(f);return $CLJS.R.j(f,$CLJS.NY,e.h?e.h(k):e.call(null,k))},$CLJS.r1(a,c))});