var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var GX,Ona,Pna,Qna,JX,Sna,Tna,Una,Vna,LX,MX,OX,QX,Wna,RX,SX,Xna,Yna,Zna,TX,Rna,UX,VX,$na,XX;GX=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.Xe(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
$CLJS.HX=function(a,b){return function(){function c(m,t,u){return new $CLJS.P(null,2,5,$CLJS.Q,[a.j?a.j(m,t,u):a.call(null,m,t,u),b.j?b.j(m,t,u):b.call(null,m,t,u)],null)}function d(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[a.g?a.g(m,t):a.call(null,m,t),b.g?b.g(m,t):b.call(null,m,t)],null)}function e(m){return new $CLJS.P(null,2,5,$CLJS.Q,[a.h?a.h(m):a.call(null,m),b.h?b.h(m):b.call(null,m)],null)}function f(){return new $CLJS.P(null,2,5,$CLJS.Q,[a.o?a.o():a.call(null),b.o?b.o():b.call(null)],null)}
var k=null,l=function(){function m(u,v,x,A){var C=null;if(3<arguments.length){C=0;for(var G=Array(arguments.length-3);C<G.length;)G[C]=arguments[C+3],++C;C=new $CLJS.w(G,0,null)}return t.call(this,u,v,x,C)}function t(u,v,x,A){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ne.M(a,u,v,x,A),$CLJS.Ne.M(b,u,v,x,A)],null)}m.A=3;m.B=function(u){var v=$CLJS.z(u);u=$CLJS.B(u);var x=$CLJS.z(u);u=$CLJS.B(u);var A=$CLJS.z(u);u=$CLJS.Hc(u);return t(v,x,A,u)};m.l=t;return m}();k=function(m,t,u,v){switch(arguments.length){case 0:return f.call(this);
case 1:return e.call(this,m);case 2:return d.call(this,m,t);case 3:return c.call(this,m,t,u);default:var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.w(A,0,null)}return l.l(m,t,u,x)}throw Error("Invalid arity: "+arguments.length);};k.A=3;k.B=l.B;k.o=f;k.h=e;k.g=d;k.j=c;k.l=l.l;return k}()};$CLJS.IX=function(a,b){return $CLJS.TK(a)(b,$CLJS.xf,$CLJS.xf)};
Ona=function(a,b,c){return $CLJS.cb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.$E,b),$CLJS.Wr(2,2,c))};Pna=function(a,b){var c=$CLJS.Gl(a,$CLJS.IP);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Cb(c);b=$CLJS.gs.g?$CLJS.gs.g(c,b):$CLJS.gs.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.IP,b)}return a};Qna=function(a){return $CLJS.bf.g($CLJS.OU(a),$CLJS.PU(a))};
JX=function(a,b,c){var d=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.Dr,null,$CLJS.Uk,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.st(2,c):$CLJS.st(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?GX(2,c):GX(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.yW.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.vd(k)?$CLJS.Wf.j($CLJS.xf,$CLJS.mk.h($CLJS.al),l):$CLJS.td(k)?$CLJS.Jz($CLJS.Sa,l):l}):$CLJS.ek.g(a,f)};
Sna=function(a){return $CLJS.cb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return JX(b,Rna,d)},a,Qna(a))};
Tna=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.tK.h(a),b);a:{var d=c,e=$CLJS.OD;$CLJS.OD=!0;try{for(var f=$CLJS.xf;;){var k=$CLJS.z($CLJS.cf.g($CLJS.HX($CLJS.Vi,$CLJS.Pk),$CLJS.ff($CLJS.lk.j(KX,$CLJS.z,$CLJS.Pk),$CLJS.Hr.h($CLJS.IX($CLJS.nV,d)))));if($CLJS.n(k)){var l=k,m=$CLJS.I(l,0,null),t=$CLJS.I(l,1,null),u=JX(d,m,t);l=$CLJS.xh;var v=l.l,x=m;var A=$CLJS.n(x)?x:$CLJS.YK($CLJS.IX($CLJS.nV,d));var C=v.call(l,$CLJS.H([A]));console.warn("Clean: Removing bad clause due to error!",t,C,$CLJS.xV($CLJS.z($CLJS.Zs(d,
u))));if($CLJS.E.g(u,d)){var G=d;break a}l=u;var J=$CLJS.Yd.g(f,new $CLJS.P(null,2,5,$CLJS.Q,[m,t],null));d=l;f=J}else{G=d;break a}}}finally{$CLJS.OD=e}G=void 0}e=Sna(G);if($CLJS.E.g(c,e)){if($CLJS.E.g(b,$CLJS.D($CLJS.tK.h(a))-1))return a;b+=1}else a=$CLJS.ok.M(a,$CLJS.tK,$CLJS.R,b,e)}};Una=function(a){var b=$CLJS.KV();return $CLJS.nk.g(function(c){return $CLJS.E.g($CLJS.WD.h(c),"__join")?$CLJS.ok.j(c,$CLJS.WD,b):c},a)};
Vna=function(a){return"string"===typeof $CLJS.hO.h(a)?$CLJS.ek.g($CLJS.R.j(a,$CLJS.QU,$CLJS.IV($CLJS.hO.h(a))),$CLJS.hO):a};LX=function(){return $CLJS.mk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.he(b))?(b=$CLJS.de(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};MX=function(a){return $CLJS.Wf.j($CLJS.N,LX(),a)};
OX=function(a){return $CLJS.Be($CLJS.Wf.j($CLJS.N,$CLJS.lk.g(LX(),$CLJS.mk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.qi)})),$CLJS.Dd(a,$CLJS.NX)?$CLJS.ek.l(a,$CLJS.NX,$CLJS.H([$CLJS.tA])):a))};
QX=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);var d=$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.cf.h($CLJS.PX),c);a=$CLJS.E.g(a,$CLJS.TE)&&$CLJS.B(c)?$CLJS.Yd.g($CLJS.od(d),new $CLJS.h(null,1,[$CLJS.Oh,$CLJS.nd(d)],null)):d;b=$CLJS.Be(OX(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xL,a,b],null):a};Wna=function(a){return $CLJS.Wf.j($CLJS.xf,$CLJS.lk.g($CLJS.cf.h(function(b){return $CLJS.Ez(b,$CLJS.FA)}),$CLJS.cf.h($CLJS.PX)),$CLJS.EV.h(a))};
RX=function(a){a=$CLJS.O(a);a=$CLJS.K.g(a,$CLJS.tK);a=$CLJS.z($CLJS.cb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.PX.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.KP,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.UN,Wna(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.qU.h(c)],null)},null,a));return $CLJS.n($CLJS.lP.h(a))?$CLJS.BU(a,new $CLJS.h(null,1,[$CLJS.lP,$CLJS.GE],null)):a};
SX=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.K.g(a,b)),1)?$CLJS.VV(a,b,$CLJS.lk.g($CLJS.PX,$CLJS.z)):a;a=1<$CLJS.D($CLJS.K.g(a,b))?$CLJS.VV(d,b,function(e){return $CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.nr],null),$CLJS.cf.h($CLJS.PX),e)}):d;return $CLJS.BU(a,$CLJS.Ae([b,c]))};Xna=function(a){var b=$CLJS.QU.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.ek.g(a,$CLJS.QU),$CLJS.hO,["card__",$CLJS.p.h(b)].join("")):a};
$CLJS.NX=new $CLJS.M("metabase.lib.query","transformation-added-base-type","metabase.lib.query/transformation-added-base-type",121891381);Yna=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);Zna=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);TX=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Rna=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);
UX=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);VX=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.WX=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);$na=new $CLJS.M(null,"m","m",1632677161);XX=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var KX,aoa,boa,coa,doa,eoa,foa,goa,hoa,ioa,joa;$CLJS.YX=$CLJS.N;$CLJS.ZX=$CLJS.N;KX=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.kV,null,$CLJS.SD,null,$CLJS.GM,null,$CLJS.bP,null,$CLJS.ZE,null,$CLJS.QQ,null,$CLJS.$E,null],null),null);aoa=$CLJS.Se($CLJS.N);boa=$CLJS.Se($CLJS.N);coa=$CLJS.Se($CLJS.N);doa=$CLJS.Se($CLJS.N);eoa=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Si,$CLJS.gB],null),$CLJS.Si,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.$X=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.XA,eoa,aoa,boa,coa,doa);$CLJS.$X.m(null,$CLJS.Oh,function(a){if($CLJS.vd(a)&&$CLJS.z(a)instanceof $CLJS.M){var b=$CLJS.nd(a);var c=$CLJS.td(b);a=c?$CLJS.od(a):a;var d=$CLJS.y(a);a=$CLJS.z(d);d=$CLJS.B(d);b=$CLJS.rV($CLJS.Wf.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.cf.h($CLJS.$X),d))}else b=a;return b});$CLJS.$X.m(null,$CLJS.jV,function(a){return a});
$CLJS.$X.m(null,$CLJS.XU,function(a){var b=$CLJS.$X.h($CLJS.$E.h(a)),c=$CLJS.Be($CLJS.nk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.BV($CLJS.$X.h(t),u)},$CLJS.bP.h(a))),d=$CLJS.Wf.j($CLJS.N,$CLJS.Hl(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.K.g(u,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.Wf.j($CLJS.N,$CLJS.Hl(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.K.g(u,$CLJS.yD);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.ZX,k=$CLJS.YX;$CLJS.ZX=d;$CLJS.YX=e;try{var l=Ona(Vna(a),b,$CLJS.H([$CLJS.bP,c])),m=$CLJS.cb(function(t,u){return $CLJS.Ra($CLJS.K.g(t,u))?t:$CLJS.ok.j(t,u,$CLJS.$X)},l,$CLJS.fk.l(KX,$CLJS.$E,$CLJS.H([$CLJS.bP])));return $CLJS.n($CLJS.GM.h(m))?$CLJS.ok.j(m,$CLJS.GM,Una):m}finally{$CLJS.YX=k,$CLJS.ZX=f}});$CLJS.$X.m(null,$CLJS.dV,function(a){return Pna(a,function(b){return $CLJS.VV(b,$CLJS.DE,$CLJS.$X)})});
$CLJS.$X.m(null,$CLJS.oK,function(a){a=$CLJS.ok.j($CLJS.ok.j(a,$CLJS.wK,$CLJS.$X),$CLJS.tK,$CLJS.$X);var b=$CLJS.n($CLJS.SD.h(a))?$CLJS.ok.j(a,$CLJS.SD,function(c){return $CLJS.ml(c)?$CLJS.nk.g($CLJS.$X,c):$CLJS.mh.h(c)}):a;return $CLJS.Ra($CLJS.WD.h(a))?$CLJS.R.j(b,$CLJS.WD,"__join"):b});$CLJS.$X.m(null,$CLJS.nA,function(a){return $CLJS.nk.g($CLJS.$X,a)});
$CLJS.$X.m(null,$CLJS.pA,function(a){return $CLJS.n($CLJS.Vi.h(a))?Tna($CLJS.R.j($CLJS.ok.j($CLJS.FV(a),$CLJS.tK,function(b){return $CLJS.nk.g($CLJS.$X,b)}),$CLJS.WX,!0)):$CLJS.gs(a,$CLJS.$X)});$CLJS.$X.m(null,$CLJS.kF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.td(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.rV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,a,b],null))});
$CLJS.$X.m(null,$CLJS.hj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.BU(a,new $CLJS.h(null,3,[$CLJS.dC,$CLJS.tA,$CLJS.GN,$CLJS.wj,$CLJS.YQ,$CLJS.sD],null));var c=$CLJS.R.j;var d=$CLJS.qi.h(a);$CLJS.n(d)||(d=$CLJS.tA.h(a),d=$CLJS.n(d)?d:$CLJS.MD(b));a=c.call($CLJS.R,a,$CLJS.qi,d);return $CLJS.rV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hj,a,b],null))});
$CLJS.$X.m(null,$CLJS.TE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.Oh.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TE,$CLJS.ek.g(c,$CLJS.Oh),$CLJS.nk.g($CLJS.$X,b)],null);b=$CLJS.rV(b);return null!=a?$CLJS.Yd.g(b,$CLJS.$X.h(a)):b});$CLJS.$X.m(null,$CLJS.qA,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.rV(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.$X.m(null,$CLJS.$E,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null),e=$CLJS.Q;var f=$CLJS.K.g($CLJS.ZX,c);if(!$CLJS.n(f))throw $CLJS.Uh(["Error converting :aggregation reference: no aggregation at index ",$CLJS.p.h(c)].join(""),new $CLJS.h(null,1,[$CLJS.aR,a],null));return $CLJS.rV(new $CLJS.P(null,3,5,e,[b,d,f],null))});
$CLJS.$X.m(null,$CLJS.xL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.$X.h(b);var c=$CLJS.y(b);b=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.Dk.l($CLJS.H([c,a]))],null),d)});foa=$CLJS.Se($CLJS.N);goa=$CLJS.Se($CLJS.N);hoa=$CLJS.Se($CLJS.N);ioa=$CLJS.Se($CLJS.N);joa=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Si,$CLJS.gB],null),$CLJS.Si,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.PX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.XA,joa,foa,goa,hoa,ioa);
$CLJS.PX.m(null,$CLJS.Oh,function(a){if($CLJS.vd(a)&&$CLJS.z(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);if($CLJS.td(a)){c=$CLJS.Wf.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.bf.g;d=$CLJS.cf.g($CLJS.PX,d);a=OX(a);a=c.call($CLJS.Wf,b,e.call($CLJS.bf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.cf.g($CLJS.PX,$CLJS.ae(a,d)))}else a=$CLJS.td(a)?$CLJS.gs(MX(a),$CLJS.PX):
a;return a});for(var aY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[XX,VX],null)),bY=null,cY=0,dY=0;;)if(dY<cY){var koa=bY.X(null,dY);$CLJS.qE(koa,UX);dY+=1}else{var eY=$CLJS.y(aY);if(eY){var fY=eY;if($CLJS.wd(fY)){var gY=$CLJS.ic(fY),loa=$CLJS.jc(fY),moa=gY,noa=$CLJS.D(gY);aY=loa;bY=moa;cY=noa}else{var ooa=$CLJS.z(fY);$CLJS.qE(ooa,UX);aY=$CLJS.B(fY);bY=null;cY=0}dY=0}else break}
for(var hY=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Rw,$CLJS.eF,$CLJS.tF,$CLJS.PE,$CLJS.Gj,$CLJS.xF,$CLJS.ui,$CLJS.WE,$CLJS.rF,$CLJS.yF,$CLJS.wF,$CLJS.DF],null)),iY=null,jY=0,kY=0;;)if(kY<jY){var poa=iY.X(null,kY);$CLJS.qE(poa,XX);kY+=1}else{var lY=$CLJS.y(hY);if(lY){var mY=lY;if($CLJS.wd(mY)){var nY=$CLJS.ic(mY),qoa=$CLJS.jc(mY),roa=nY,soa=$CLJS.D(nY);hY=qoa;iY=roa;jY=soa}else{var toa=$CLJS.z(mY);$CLJS.qE(toa,XX);hY=$CLJS.B(mY);iY=null;jY=0}kY=0}else break}
for(var oY=$CLJS.y($CLJS.tf([$CLJS.Mq,$CLJS.Zr,$CLJS.Nq,$CLJS.EF,$CLJS.TE,$CLJS.nF,$CLJS.KE,$CLJS.GF,$CLJS.BE,$CLJS.YE,$CLJS.FE,$CLJS.iF,$CLJS.pF,$CLJS.HE,$CLJS.ZA,$CLJS.$A,$CLJS.Fx,$CLJS.XH,$CLJS.bI,$CLJS.RH,$CLJS.aB,$CLJS.WH,$CLJS.PH,$CLJS.eI,$CLJS.jI,$CLJS.gI,$CLJS.VH,$CLJS.lI,$CLJS.bB,$CLJS.eB,$CLJS.JE,$CLJS.CE,$CLJS.HF,$CLJS.XG,$CLJS.mF,$CLJS.Pw,$CLJS.zF,$CLJS.BF,$CLJS.IF,$CLJS.js,$CLJS.ps],!0)),pY=null,qY=0,rY=0;;)if(rY<qY){var uoa=pY.X(null,rY);$CLJS.qE(uoa,VX);rY+=1}else{var sY=$CLJS.y(oY);
if(sY){var tY=sY;if($CLJS.wd(tY)){var uY=$CLJS.ic(tY),voa=$CLJS.jc(tY),woa=uY,xoa=$CLJS.D(uY);oY=voa;pY=woa;qY=xoa}else{var yoa=$CLJS.z(tY);$CLJS.qE(yoa,VX);oY=$CLJS.B(tY);pY=null;qY=0}rY=0}else break}$CLJS.PX.m(null,UX,function(a){return QX(a)});$CLJS.PX.m(null,$CLJS.pA,function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.lk.g(LX(),$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.PX.h(b)],null)})),a)});
$CLJS.PX.m(null,$CLJS.$E,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.td(b))try{var d=OX(b);b=$CLJS.Q;var e=$CLJS.YX,f=$CLJS.K.j(e,c,TX);if($CLJS.E.g(f,TX))throw $CLJS.Uh(["Unable to find key ",$CLJS.xh.l($CLJS.H([c]))," in map."].join(""),new $CLJS.h(null,2,[$na,e,$CLJS.Vv,c],null));var k=new $CLJS.P(null,2,5,b,[$CLJS.$E,f],null);return $CLJS.y(d)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xL,k,d],null):k}catch(l){throw c=l,d=$CLJS.vU(c),d=$CLJS.ha.g?$CLJS.ha.g("Error converting aggregation reference to pMBQL: %s",
d):$CLJS.ha.call(null,"Error converting aggregation reference to pMBQL: %s",d),$CLJS.Th(d,new $CLJS.h(null,1,[$CLJS.V,a],null),c);}else return a});$CLJS.PX.m(null,$CLJS.nA,function(a){return $CLJS.nk.g($CLJS.PX,a)});
$CLJS.PX.m(null,$CLJS.kF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.td(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,$CLJS.PX.h(a),OX(b)],null)});
$CLJS.PX.m(null,$CLJS.hj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=OX($CLJS.BU(b,new $CLJS.h(null,3,[$CLJS.tA,$CLJS.dC,$CLJS.wj,$CLJS.GN,$CLJS.sD,$CLJS.YQ],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hj,a,b],null)});
$CLJS.PX.m(null,$CLJS.oK,function(a){var b=MX(a);a=0==$CLJS.WD.h(a).lastIndexOf("__join",0)?$CLJS.ek.g(b,$CLJS.WD):b;return $CLJS.Dk.l($CLJS.H([$CLJS.gs($CLJS.ek.l(a,$CLJS.tK,$CLJS.H([$CLJS.wK])),$CLJS.PX),SX($CLJS.Fl(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.wK],null)),$CLJS.wK,$CLJS.SQ),RX(a)]))});
$CLJS.PX.m(null,$CLJS.XU,function(a){var b=$CLJS.$E.h(a),c=$CLJS.Wf.j($CLJS.N,$CLJS.Hl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.K.g(k,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.Hl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.K.g(k,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.ZX,e=$CLJS.YX;$CLJS.ZX=c;$CLJS.YX=b;try{return $CLJS.cb(function(f,k){return $CLJS.VV(f,
k,$CLJS.PX)},SX($CLJS.VV($CLJS.VV(Xna(MX(a)),$CLJS.$E,function(f){return $CLJS.nk.g(QX,f)}),$CLJS.bP,function(f){return $CLJS.Wf.g($CLJS.N,function(){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.wd(t)){var u=$CLJS.ic(t),v=$CLJS.D(u),x=$CLJS.le(v);a:for(var A=0;;)if(A<v){var C=$CLJS.id(u,A),G=$CLJS.PX.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AV(C),$CLJS.E.g($CLJS.hj,$CLJS.z(G))?$CLJS.dd(G):G],null);x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.oe($CLJS.qe(x),
m($CLJS.jc(t))):$CLJS.oe($CLJS.qe(x),null)}x=$CLJS.z(t);u=$CLJS.PX.h(x);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AV(x),$CLJS.E.g($CLJS.hj,$CLJS.z(u))?$CLJS.dd(u):u],null),m($CLJS.Hc(t)))}return null}},null,null)}(f)}())}),$CLJS.kV,$CLJS.OE),$CLJS.fk.l(KX,$CLJS.$E,$CLJS.H([$CLJS.kV,$CLJS.bP])))}finally{$CLJS.YX=e,$CLJS.ZX=d}});$CLJS.PX.m(null,$CLJS.dV,function(a){return $CLJS.gs(MX(a),$CLJS.PX)});
$CLJS.PX.m(null,$CLJS.jV,function(a){try{var b=MX(a),c=$CLJS.xM.h(b),d=RX(b),e=$CLJS.E.g($CLJS.Vs.h($CLJS.fd($CLJS.tK.h(a))),$CLJS.dV)?$CLJS.lP:$CLJS.GE;return $CLJS.Dk.l($CLJS.H([$CLJS.ek.l(b,$CLJS.tK,$CLJS.H([$CLJS.xM,$CLJS.WX])),function(){var k=$CLJS.Ae([$CLJS.Vi,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.xM,c):k}()]))}catch(k){var f=k;throw $CLJS.Th(function(){var l=$CLJS.vU(f);return $CLJS.ha.g?$CLJS.ha.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ha.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.GE,a],null),f);}});
$CLJS.vY=function(){function a(d,e,f){f=$CLJS.FX(null,$CLJS.Ly(f,$CLJS.H([$CLJS.li,!0])));var k=$CLJS.HV(d,e);k=$CLJS.O(k);var l=$CLJS.K.g(k,$CLJS.$E);k=$CLJS.Wf.j($CLJS.N,$CLJS.Hl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.K.g(x,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.Wf.j($CLJS.N,$CLJS.Hl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.K.g(x,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.ZX,t=$CLJS.YX;$CLJS.ZX=k;$CLJS.YX=l;try{try{return $CLJS.$X.h(f)}catch(v){var u=v;throw $CLJS.Th(function(){var x=$CLJS.vU(u);return $CLJS.ha.g?$CLJS.ha.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.ha.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.GE,d,$CLJS.HK,e,Zna,f,Yna,$CLJS.ZX],null),u);}}finally{$CLJS.YX=t,$CLJS.ZX=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();