var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var S3,U3,W3,X3,Y3,Z3,$3,Fra;S3=function(a,b){if(null!=a&&null!=a.vg)a=a.vg(a,b);else{var c=S3[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=S3._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.metric",a);}return a};$CLJS.T3=function(a,b){return S3($CLJS.e_(a),b)};
U3=function(a,b,c){var d=$CLJS.HV(a,b);d=$CLJS.O(d);d=$CLJS.K.g(d,$CLJS.$E);d=$CLJS.sG($CLJS.lk.j($CLJS.Tg([c]),$CLJS.yD,$CLJS.dd),d);if(!$CLJS.n(d))throw $CLJS.Uh($CLJS.gD("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.Bj,c,$CLJS.GE,a,$CLJS.HK,b],null));return d};$CLJS.V3=function(a){return $CLJS.J1($CLJS.wF,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};W3=new $CLJS.M("metabase.lib.aggregation","no-semantic-type","metabase.lib.aggregation/no-semantic-type",-789440092);
X3=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);Y3=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);Z3=new $CLJS.M("metabase.lib.aggregation","quantity-aggregation","metabase.lib.aggregation/quantity-aggregation",411632918);$3=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);
Fra=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.R_.m(null,$CLJS.$E,function(a,b){var c=$CLJS.Be($CLJS.$E.h($CLJS.HV(a,b)));return $CLJS.n(c)?$CLJS.M1($CLJS.JD("and"),function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.id(l,u);v=$CLJS.n_.v(a,b,v,$CLJS.o_);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}t=$CLJS.z(k);return $CLJS.ae($CLJS.n_.v(a,
b,t,$CLJS.o_),f($CLJS.Hc(k)))}return null}},null,null)}(c)}()):null});$CLJS.V_.m(null,$CLJS.$E,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.K.g(e,$CLJS.tA);e=$CLJS.K.g(e,$CLJS.qi);c=$CLJS.I(c,2,null);c=U3(a,b,c);return $CLJS.Dk.l($CLJS.H([$CLJS.l_.j(a,b,c),new $CLJS.h(null,2,[$CLJS.NJ,$CLJS.mK,$CLJS.F0,$CLJS.yD.h($CLJS.dd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.tA,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.qi,e],null):null]))});
$CLJS.O_.m(null,$CLJS.$E,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.n_.v(a,b,U3(a,b,c),d)});$CLJS.qE(Y3,$3);
for(var a4=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rw,$CLJS.lF,$CLJS.tF],null)),b4=null,c4=0,d4=0;;)if(d4<c4){var Gra=b4.X(null,d4);$CLJS.qE(Gra,Y3);d4+=1}else{var e4=$CLJS.y(a4);if(e4){var f4=e4;if($CLJS.wd(f4)){var g4=$CLJS.ic(f4),Hra=$CLJS.jc(f4),Ira=g4,Jra=$CLJS.D(g4);a4=Hra;b4=Ira;c4=Jra}else{var Kra=$CLJS.z(f4);$CLJS.qE(Kra,Y3);a4=$CLJS.B(f4);b4=null;c4=0}d4=0}else break}
$CLJS.O_.m(null,Y3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.n_.v(a,b,c,d),e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.gD("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.gD("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.JD("Count");case "cum-count":return $CLJS.JD("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.P_.m(null,Y3,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";case "count-where":return"count_where";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.V_.m(null,Z3,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.lH($CLJS.V_,$3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.wj,$CLJS.TB)});
$CLJS.qE(Z3,$3);$CLJS.qE(Y3,Z3);$CLJS.qE($CLJS.PE,Z3);$CLJS.O_.m(null,$CLJS.TE,function(){return $CLJS.JD("Case")});$CLJS.P_.m(null,$CLJS.TE,function(){return"case"});$CLJS.qE(X3,$3);
for(var h4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.eF,$CLJS.FF,$CLJS.PE,$CLJS.Gj,$CLJS.xF,$CLJS.ui,$CLJS.yF,$CLJS.wF,$CLJS.QE],null)),i4=null,j4=0,k4=0;;)if(k4<j4){var Lra=i4.X(null,k4);$CLJS.qE(Lra,X3);k4+=1}else{var l4=$CLJS.y(h4);if(l4){var m4=l4;if($CLJS.wd(m4)){var n4=$CLJS.ic(m4),Mra=$CLJS.jc(m4),Nra=n4,Ora=$CLJS.D(n4);h4=Mra;i4=Nra;j4=Ora}else{var Pra=$CLJS.z(m4);$CLJS.qE(Pra,X3);h4=$CLJS.B(m4);i4=null;j4=0}k4=0}else break}
$CLJS.P_.m(null,X3,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.O_.m(null,X3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.n_.v(a,b,c,d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "avg":return $CLJS.gD("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.gD("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.gD("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.gD("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.gD("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.gD("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.gD("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.gD("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.gD("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.O_.m(null,$CLJS.WE,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.gD("{0}th percentile of {1}",$CLJS.H([c,$CLJS.n_.v(a,b,e,d)]))});$CLJS.P_.m(null,$CLJS.WE,function(){return"percentile"});
$CLJS.qE(W3,$3);for(var o4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WE,$CLJS.QE],null)),p4=null,q4=0,r4=0;;)if(r4<q4){var Qra=p4.X(null,r4);$CLJS.qE(Qra,W3);r4+=1}else{var s4=$CLJS.y(o4);if(s4){var t4=s4;if($CLJS.wd(t4)){var u4=$CLJS.ic(t4),Rra=$CLJS.jc(t4),Sra=u4,Tra=$CLJS.D(u4);o4=Rra;p4=Sra;q4=Tra}else{var Ura=$CLJS.z(t4);$CLJS.qE(Ura,W3);o4=$CLJS.B(t4);p4=null;q4=0}r4=0}else break}
$CLJS.V_.m(null,W3,function(a,b,c){var d=$CLJS.ek.g,e=$CLJS.lH($CLJS.V_,$3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.ek,a,$CLJS.wj)});$CLJS.O_.m(null,$CLJS.DF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.gD("Sum of {0} matching condition",$CLJS.H([$CLJS.n_.v(a,b,e,d)]))});
$CLJS.P_.m(null,$CLJS.DF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.Q_.j(a,b,d))].join("")});$CLJS.qE($CLJS.DF,$3);$CLJS.O_.m(null,$CLJS.rF,function(){return $CLJS.JD("Share of rows matching condition")});$CLJS.P_.m(null,$CLJS.rF,function(){return"share"});$CLJS.V_.m(null,$CLJS.rF,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.lH($CLJS.V_,$3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.wj,$CLJS.kC)});
$CLJS.qE($CLJS.rF,$3);$CLJS.O_.m(null,$CLJS.tF,function(){return $CLJS.JD("Count of rows matching condition")});$CLJS.V_.m(null,$3,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.Dk.l;d=$CLJS.n(d)?$CLJS.Fl($CLJS.l_.j(a,b,d),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cK,$CLJS.wj],null)):null;var f=$CLJS.lH($CLJS.V_,$CLJS.Oh);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.Dk,$CLJS.H([d,a]))});
$CLJS.Vra=function(){function a(d){return $CLJS.J1($CLJS.Rw,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.J1($CLJS.Rw,$CLJS.xf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.qV.m(null,$CLJS.$E,function(a){return a});
$CLJS.v4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.XA(f),$CLJS.RJ))f=$CLJS.sV(f);else return $CLJS.O1(d,e,$CLJS.$E,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.w4=function(){function a(d,e){return $CLJS.Be($CLJS.$E.h($CLJS.HV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.x4=function(){function a(d,e){var f=$CLJS.Be($CLJS.$E.h($CLJS.HV(d,e)));return null==f?null:$CLJS.Wf.j($CLJS.xf,$CLJS.cf.h(function(k){var l=$CLJS.l_.j(d,e,k),m=$CLJS.R.l,t=$CLJS.tA.h(l);return m.call($CLJS.R,$CLJS.I1(l,$CLJS.qi,$CLJS.n(t)?t:$CLJS.fj),$CLJS.NJ,$CLJS.mK,$CLJS.H([$CLJS.F0,$CLJS.yD.h($CLJS.dd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.O_.m(null,$CLJS.RF,function(a,b,c){a=$CLJS.O(c);a=$CLJS.K.g(a,$CLJS.NF);return $CLJS.lD.h(a.o?a.o():a.call(null))});$CLJS.W_.m(null,$CLJS.RF,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.K.g(d,$CLJS.dF);b=$CLJS.K.g(d,$CLJS.NF);c=$CLJS.K.g(d,$CLJS.MF);d=$CLJS.K.g(d,$CLJS.OY);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.M_,$CLJS.$z(a),$CLJS.H([$CLJS.K_,c]));return null!=d?$CLJS.R.j(a,$CLJS.v_,d):a});
$CLJS.Wra=function(){function a(d,e){var f=function(){var m=$CLJS.dz.h($CLJS.a1(d));return $CLJS.n(m)?m:$CLJS.Sg}(),k=$CLJS.HV(d,e),l=$CLJS.$_.j(d,e,k);return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.lk.j($CLJS.ef(function(m){m=$CLJS.PF.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.xl(function(m){m=$CLJS.O(m);var t=$CLJS.K.g(m,$CLJS.MF),u=$CLJS.K.g(m,$CLJS.KF);if($CLJS.Ra(t))return m;if($CLJS.E.g(u,$CLJS.Zh))return $CLJS.R.j(m,$CLJS.EV,l);t=$CLJS.Be($CLJS.A0(function(v){return $CLJS.EZ(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.EV,t):null}),$CLJS.cf.h(function(m){return $CLJS.R.j(m,$CLJS.Vs,$CLJS.RF)})),$CLJS.SF))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Xra=function(){function a(d,e){return $CLJS.rV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dF.h(d),$CLJS.N,$CLJS.tV.h(e)],null))}function b(d){if($CLJS.Ra($CLJS.MF.h(d)))return $CLJS.rV(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dF.h(d),$CLJS.N],null));var e=$CLJS.dF.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.Uh(e,new $CLJS.h(null,1,[Fra,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();